import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/16122024.jpg"

const News161224 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
           Открыта работа Всероссийского научно-методического практикума 
по актуальным вопросам профилактики девиантного поведения 
несовершеннолетних
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <b>

        ВНИМАНИЕ!  
            </b>
            <p></p>
с 17 декабря 2024 года открыта <b><a href="https://forms.yandex.ru/u/675fd43a505690fcfca87807/">РЕГИСТРАЦИЯ</a></b> на Всероссийский научно-
методический практикум по актуальным вопросам профилактики девиантного 
поведения несовершеннолетних.
<p></p>
Материалы Практикума доступны участникам <b>
с 17 декабря 2024 г. по 31 января 2025 г. </b>
<p></p>
По итогам участия в Практикуме (ознакомление с презентационными 
иметодическими материалами, просмотр видеоматериалов) предусмотрено 
получение <b> электронного сертификата.</b>
           
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News161224;
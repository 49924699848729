import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/Conception2024.png"

import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"

import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const ChildDefence2024 = ({url, header, children, style = {}}) => {
    return <div style={{width: "90%", maxWidth: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Всероссийский научно методический практикум по актуальным вопросам профилактики девиантного
                    поведения несовершеннолетних

                </h2>
                <img alt="" src={PageImg}/>
                <p>
                    В рамках исполнения пункта 26 плана мероприятий на 2021–2025 годы
                    по реализации Концепции развития системы профилактики безнадзорности и правонарушений
                    несовершеннолетних на период до 2025 года, утвержденного распоряжением Правительства Российской
                    Федерации
                    от 18 марта 2021 г. № 656-р, Минпросвещения России с 17 декабря 2024 г. по 31 января 2025 г.
                    проводит Всероссийский научно-методический практикум по актуальным вопросам профилактики девиантного
                    поведения несовершеннолетних.
                </p>
                <p>
                    Цель Практикума – содействие развитию системы профилактической деятельности образовательных
                    организаций, включая выявление
                    и устранение причин и условий, способствующих правонарушениям
                    и антиобщественным действиям несовершеннолетних, раннее предупреждение и коррекцию девиантного
                    поведения детей и подростков
                    с учетом актуальных социальных рисков на основе современных научно-практических методов и ресурсов.
                </p>
                <p>
                    Участники Практикума (целевая группа) – представители органов и учреждений системы профилактики
                    безнадзорности и правонарушений
                    несовершеннолетних субъектов Российской Федерации; образовательных, научных, общественных
                    организаций.
                </p>
                <p>
                    Организатор Практикума – федеральное государственное бюджетное учреждение «Центр защиты прав и
                    интересов детей».
                </p>
                <p>
                    Практикум проводится в заочном формате.
                </p>
                <p>
                    По итогам участия в Практикуме (ознакомление с презентационными и методическими материалами,
                    просмотр видеоматериалов) предусмотрено получение электронного сертификата.
                </p>
                <p>
                    <a className={s.concepcA}
                       href="https://fcprc.ru/media/media/behavior/Программа_Практикум_Профилактика_2024.pdf">Программа Практикума
                    </a>
                </p>
                <p>
                    <a className={s.concepcA} href="https://clck.ru/3FQvmq">Материалы Практикума</a>
                </p>
                <p>
                    <a className={s.concepcA} href="https://fcprc.ru/media/media/behavior/Сертификат_участника_для_заполнения.pdf">Сертификат</a>
                </p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>


        </div>
    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
    {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default ChildDefence2024
import React from "react";
import Falldown, {Falldown2, HrefLi, FileLi} from "../common/FalldownList/Falldown";
import img21 from "../../assets/img/1.png"
import s from "./Mediacia.module.css"

import {useEffect, useState} from "react";
import axios from "axios";


const MetodolDev = () => {
    const [respData, setData] = useState()

    useEffect(() => {
        axios.get("https://fcprc.ru/api/metdev").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.newHeader}>
        <h2>
            Методические разработки
        </h2>

        {(respData ? respData[0] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[0].img} header={respData[0].theme}>
                <>
                    <FileLi href={respData[0].files[15].file} text="Позитивная социализация обучающихся в процессе образования: взаимодействие социального педагога общеобразовательной организации с родителями (законными представителями): информационно-методические материалы для социальных педагогов общеобразовательных организаций (2024)"/>
                    <FileLi href={respData[0].files[0].file} text={respData[0].files[0].name}/>
                    <FileLi href={respData[0].files[1].file} text={respData[0].files[1].name}/>
                    <FileLi href={respData[0].files[2].file} text={respData[0].files[2].name}/>
                    <FileLi href={respData[0].files[3].file} text={respData[0].files[3].name}/>
                    <FileLi href={respData[0].files[4].file} text={respData[0].files[4].name}/>
                    <FileLi href={respData[0].files[5].file} text={respData[0].files[5].name}/>
                    <FileLi href={respData[0].files[6].file} text={respData[0].files[6].name}/>
                    <FileLi href={respData[0].files[7].file} text={respData[0].files[7].name}/>
                    <FileLi href={respData[0].files[8].file} text={respData[0].files[8].name}/>
                    <FileLi href={respData[0].files[9].file} text={respData[0].files[9].name}/>
                    <FileLi href={respData[0].files[10].file} text={respData[0].files[10].name}/>
                    <FileLi href={respData[0].files[11].file} text={respData[0].files[11].name}/>
                    <FileLi href={respData[0].files[12].file} text={respData[0].files[12].name}/>
                    <FileLi href={respData[0].files[13].file} text={respData[0].files[13].name}/>
                    <FileLi href={respData[0].files[14].file} text={respData[0].files[14].name}/>
                </>
                {respData[0].refs ? respData[0].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[0].children ? respData[0].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[1] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[1].img} header={respData[1].theme}>
                {respData[1].files ?
                    <>
                        <FileLi href={respData[1].files[4].file} text={respData[1].files[4].name}/>
                        <FileLi href={respData[1].files[3].file} text={respData[1].files[3].name}/>
                        <FileLi href={respData[1].files[2].file} text={respData[1].files[2].name}/>
                        <FileLi href={respData[1].files[1].file} text={respData[1].files[1].name}/>
                        <FileLi href={respData[1].files[0].file} text={respData[1].files[0].name}/>
                    </> : <></>
                }
                {respData[1].refs ? respData[1].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[1].children ? respData[1].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[2] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[2].img} header={respData[2].theme}>
                {respData[2].files ?
                    <>
                        <FileLi href={respData[2].files[6].file}
                                text={<>Методические материалы по проведению профилактических мероприятий с обучающимися образовательных организаций, направленные на формирование у них позитивного мышления, принципов здорового образа жизни, предупреждение суицидального поведения<br/>«ПОЗИТИВНОЕ МЫШЛЕНИЕ, ЭМОЦИИ, ЖИЗНЕСТОЙКОСТЬ: УЧИМСЯ УПРАВЛЯТЬ СОБОЙ»</>}/>
                        <FileLi href={respData[2].files[0].file} text="Методические рекомендации «Профилактические мероприятия в образовательных организациях, направленные на формирование у обучающимися позитивного мышления, принципов здорового образа жизни, предупреждения суицидального поведения, в том числе с учетом влияния дистантного формата обучения (2021)"/>
                        <FileLi href={respData[2].files[1].file} text={respData[2].files[1].name}/>
                        <FileLi href={respData[2].files[2].file} text={respData[2].files[2].name}/>
                        <FileLi href={respData[2].files[3].file} text={respData[2].files[3].name}/>
                        <FileLi href={respData[2].files[4].file} text={respData[2].files[4].name}/>
                        <FileLi href={respData[2].files[5].file} text={respData[2].files[5].name}/>
                    </> : <></>
                }
                {respData[2].refs ? respData[1].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[2].children ? respData[2].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[3] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[3].img} header={respData[3].theme}>
                {respData[3].files ? respData[3].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[3].refs ? respData[3].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[3].children ? respData[3].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[4] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[4].img} header={respData[4].theme}>
                {respData[4].files ? respData[4].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[4].refs ? respData[4].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[4].children ? respData[4].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[5] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[5].img} header={respData[5].theme}>
                {respData[5].files ?
                    <>
                        <FileLi href={respData[5].files[7].file} text="Методические рекомендации «Организация деятельности образовательных организаций, направленной на профилактику употребления психоактивных веществ, пропаганду здорового образа жизни среди обучающихся (на основе результатов социально-психологического тестирования) (2024)"/>
                        <FileLi href={respData[5].files[6].file} text={respData[5].files[6].name}/>
                        <FileLi href={respData[5].files[5].file} text={respData[5].files[5].name}/>
                        <FileLi href={respData[5].files[4].file} text={respData[5].files[4].name}/>
                        <FileLi href={respData[5].files[3].file} text={respData[5].files[3].name}/>
                        <FileLi href={respData[5].files[0].file} text={respData[5].files[0].name}/>
                        <FileLi href={respData[5].files[2].file} text={respData[5].files[2].name}/>
                        <FileLi href={respData[5].files[1].file} text={respData[5].files[1].name}/>
                    </> : <></>
                }
                {respData[5].refs ? respData[5].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[5].children ? respData[5].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>
                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[6] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[6].img} header={respData[6].theme}>
                                <FileLi href={"https://fcprc.ru/media/media/behavior/Перечень_информационно_методических_разработок.pdf"} text={"Перечень информационно-методических разработок в сфере профилактики преступлений в отношении половой неприкосновенности несовершеннолетних (2024)"}/>

                <FileLi href={respData[6].files[7].file} text={respData[6].files[7].name}/>
                <FileLi href={respData[6].files[6].file} text={respData[6].files[6].name}/>
                <FileLi href={respData[6].files[0].file} text={respData[6].files[0].name}/>
                <FileLi href={respData[6].files[1].file} text={respData[6].files[1].name}/>
                <FileLi href={respData[6].files[2].file} text={respData[6].files[2].name}/>
                <FileLi href={respData[6].files[3].file} text={respData[6].files[3].name}/>
                <FileLi href={respData[6].files[4].file} text={respData[6].files[4].name}/>
                <FileLi href={respData[6].files[5].file} text={respData[6].files[5].name}/>
                {respData[6].refs ? respData[6].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[6].children ? respData[6].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[7] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[7].img} header={respData[7].theme}>
                <FileLi href={respData[7].files[12].file} text={respData[7].files[12].name}/>
                <FileLi href={respData[7].files[0].file} text={respData[7].files[0].name}/>
                <FileLi href={respData[7].files[1].file} text={respData[7].files[1].name}/>
                <FileLi href={respData[7].files[2].file} text={respData[7].files[2].name}/>
                <FileLi href={respData[7].files[3].file} text={respData[7].files[3].name}/>
                <FileLi href={respData[7].files[4].file} text={respData[7].files[4].name}/>
                <FileLi href={respData[7].files[5].file} text={respData[7].files[5].name}/>
                <FileLi href={respData[7].files[6].file} text={respData[7].files[6].name}/>
                <FileLi href={respData[7].files[7].file} text={respData[7].files[7].name}/>
                <FileLi href={respData[7].files[8].file} text={respData[7].files[8].name}/>
                <FileLi href={respData[7].files[9].file} text={respData[7].files[9].name}/>
                <FileLi href={respData[7].files[10].file} text={respData[7].files[10].name}/>
                <FileLi href={respData[7].files[11].file} text={respData[7].files[11].name}/>
                {respData[7].refs ? respData[7].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[7].children ? respData[7].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[8] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[8].img} header={respData[8].theme}>
                {respData[8].files ? respData[8].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[8].refs ? respData[8].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[8].children ? respData[8].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {(respData ? respData[9] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[9].img} header={respData[9].theme}>
                {respData[9].files ? respData[9].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[9].refs ? respData[9].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[9].children ? respData[9].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        <p></p>
    </div>
}


export default MetodolDev
import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News111024_1.jpg"
import img2 from "../../assets/News/News111024_2.jpg"
import img3 from "../../assets/News/News111024_3.JPG"
import img4 from "../../assets/News/News111024_4.jpg"
import img5 from "../../assets/News/News111024_5.jpg"
import img6 from "../../assets/News/News111024_6.jpg"
import img7 from "../../assets/News/News111024_7.jpg"
import img8 from "../../assets/News/News111024_8.jpg"
import img9 from "../../assets/News/News111024_9.jpg"
import img10 from "../../assets/News/News101024_new.png"

const News101024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Программа для юных медиаторов-волонтеров «Юный медиатор» во Всероссийском детском центре «Орленок» в 2024
            году
        </h2>
        <div style={{width: "100%", margin: "0 auto", textAlign: "center"}}>
            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img10}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            С 15 по 21 сентября 2024 года по поручению Минпросвещения России сотрудники отдела медиации федерального
            государственного бюджетного учреждения «Центр защиты прав и интересов детей» при содействии Международного
            союза детских общественных объединений «Союз пионерских организаций – Федерация детских организаций» провели
            развивающую программу для юных медиаторов-волонтеров «Юный медиатор» на базе лагеря «Комсомольский»
            Всероссийского детского центра «Орленок».
            <p></p>
            В программе «Юный медиатор» приняли участие активисты и лидеры детских общественных объединений,
            занимающиеся общественно полезной деятельностью из 16 субъектов России: Брянская область, Волгоградская
            область, Воронежская область, Санкт-Петербург, Краснодарский край, Курская область, Липецкая область,
            Московская область, Орловская область, Республика Башкортостан, Республика Северная Осетия-Алания,
            Ростовская область, Саратовская область, Ставропольский край, Удмуртская Республика, Москва.
            <p></p>
            В течение одной недели более 200 мальчишек и девчонок от 12 до 17 лет участвовали в занятиях по медиации, на
            которых познакомились с понятием конфликта, причинами его возникновения и последствиями, стратегиями
            поведения в конфликтной ситуации, учились прояснять интересы собеседника и выражать свои интересы в
            конструктивном русле. Формат интерактивных занятий позволил ребятам побыть как в роли стороны конфликта, так
            и в роли медиатора, помогающего сторонам прийти к оптимальному и взаимовыгодному решению.
            <p></p>
            Все участники смены получили актуальные знания бесконфликтного общения и практический опыт взаимодействия с
            педагогами и сверстниками. По возвращению домой юные медиаторы смогут принять участие в работе служб
            медиации и примирения в своих школах, участвовать в просветительских мероприятиях медиативной
            направленности, способствовать продвижению культуры конструктивного общения и гуманистических ценностей в
            образовательных организациях, в семье, в нашем обществе в целом.
            <p></p>
            Каждому участнику были вручены сертификаты о прохождении программы, значок «Юный медиатор», блокнот и ручка
            с символикой Центра защиты прав и интересов детей.
            <p></p>
            В своих отзывах о программе участники отметили доступное для всех изложение материалов и пользу применения
            принципов медиации в лагере и вне его пределов. Ребята пришли к выводу, что, опираясь на такие принципы,
            можно лучше понимать других людей, выстраивать интересное общение и даже сохранить дружбу в конфликтных
            ситуациях.
            <p></p>
            Ведущие смены – сотрудники ФГБУ «Центр защиты прав и интересов детей»: аналитик II категории Жодзишская
            Лидия Николаевна и аналитик Тюльканов Сергей Леонидович.
        </div>
        <div style={{width: "100%", maxWidth: "600px", margin: "0 auto", textAlign: "center"}}>
            <img style={{width: "100%", maxWidth: "600px", margin: "0 auto", textAlign: "center"}} src={img1}/>

            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img2}/>

            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img3}/>

            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img4}/>

            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img5}/>

            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img6}/>

            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img7}/>

            <img style={{maxWidth: "600px", margin: "0 auto"}} src={img8}/>

            <img style={{maxWidth: "600px", margin: "0 auto", textAlign: "center"}} src={img9}/>
        </div>


        <ToNews/>
    </div>
}


export default News101024;
import React, {useEffect, useState} from 'react';
import './App.css';
import {connect, Provider} from "react-redux";
import store from "./Redux/redux-store";
import {compose} from 'redux';
import {BrowserRouter, Link, Navigate, Route, Router, Routes} from 'react-router-dom'
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import CalendarPage from './Components/Main/calendarPage';
import Mediacia from './Components/Mediacia/Mediacia';
import Vseros from './Components/Mediacia/Vseros';
import MetodolDev from './Components/Mediacia/MetodolDevl';
import Monitorings from './Components/Mediacia/Monitorings';
import Behavior from './Components/Behavior_Prevention/Behavior';
import Education from './Components/Behavior_Prevention/edu';
import Webinar from './Components/Behavior_Prevention/webinars';
import Footer from './Components/Footer/Footer';
import PageInfo from './Components/common/PageInfo/PageInfo';
import PageFRCKDN from './Components/common/PageInfo/PageInfoFRCKDN';
import PageFRCSUVU from './Components/common/PageInfo/PageInfoSUVU';
import PageArhSUVU from './Components/common/PageInfo/PageInfoSuvuArchive';
import PagePriceLife from './Components/common/PageInfo/PageInfoPriceLife';
import PagePriceLifeInfSec from './Components/common/PageInfo/PageInfoPriceLifeInfSec';
import PagePriceLifeSecWay from './Components/common/PageInfo/PageInfoPriceLifeSecWay';
import BehHelphul from './Components/common/FilePage/BehHelpful';
import PageKonceps from './Components/common/PageInfo/PageInfoKonceps';
import Hotline1 from './Components/common/PageInfo/Hotline';
import Hotline2 from './Components/common/PageInfo/Hotline2';
import Hotline3 from './Components/common/PageInfo/Hotline3';
import Hotline4 from './Components/common/PageInfo/Hotline4';
import Hotline5 from './Components/common/PageInfo/Hotline5';
import Hotline6 from './Components/common/PageInfo/Hotline6';
import Hotline7 from './Components/common/PageInfo/Hotline7';
import Hotline8 from './Components/common/PageInfo/Hotline8';
import PageMeshdSotr from './Components/common/PageInfo/PageInfoMeshdunarodnoeSotr';
import FilesForParents from "./Components/common/FilePage/FilesForParents"
import FilesSuvuPeople from "./Components/common/FilePage/FileSuvuPeople"
import FilesUstav from "./Components/common/FilePage/FilesUstav"
import FilesAntimonopo from "./Components/common/FilePage/FilesAntimonopol"
import FilesAnticor from "./Components/common/FilePage/FilesAnticor"
import FilesIncome from "./Components/common/FilePage/FilesIncome"
import FilesNPD from "./Components/common/FilePage/FilesNPD"
import FilesExperts from "./Components/common/FilePage/FilesExperts"
import FilesFZ from "./Components/common/FilePage/FilesFZ"
import FilesKDNMer from "./Components/common/FilePage/FilesKDNNorm"
import FilesKDNHis from "./Components/common/FilePage/FilesKDNHistory"
import FilesKDNGos from "./Components/common/FilePage/FilesKDNGos"
import FilesKDNDev from "./Components/common/FilePage/FilesKDNMDev"
import FilesSpid from "./Components/common/FilePage/FilesKDNVich"
import FilesKDNRights from "./Components/common/FilePage/FilesKDNRights"
import FilesKDNRead from "./Components/common/FilePage/FilesBehRead"
import FilesKDNInt from "./Components/common/FilePage/FilesKDNInternational"
import FilesKDNContacts from "./Components/common/FilePage/FilesKDNContacts"
import FilesKDNActQ from "./Components/common/FilePage/FilesActualQuestions"
import FilesKDNInfo from "./Components/common/FilePage/FilesKDNInfForm"
import FilesSuvNews from "./Components/common/FilePage/SuvuNews"
import FilesKDNReestr from "./Components/common/FilePage/FilesKDNReestr"
import FilesStrats from "./Components/common/FilePage/FilesStrats"
import FilesConceps from "./Components/common/FilePage/FileConcepc"
import FilesChild from "./Components/common/FilePage/FilesChildhood"
import FilesMer from "./Components/common/FilePage/FilesMaterials"
import FilesEffective from "./Components/common/FilePage/FilesEffective"
import BehSchool from "./Components/common/FilePage/BehSchool"
import FilesInfMatireal from "./Components/common/FilePage/FileInfMaterials"
import FilesUnDrug from "./Components/common/FilePage/FileUnDrug"
import FilesSuvuNorm from "./Components/common/FilePage/FileSuvuNorm"
import FilesSuvuMet from "./Components/common/FilePage/FileSuvuMet"
import FilesSuvuVseros from "./Components/common/FilePage/FileSuvuVseros"
import FilesAddectBehavior from "./Components/common/FilePage/FileUntiBehavior"
import Psycho from "./Components/common/FilePage/psichomet"
import AboutUs from "./Components/AboutUs/AboutUs"
import Directions from "./Components/AboutUs/directions"
import Reports from "./Components/AboutUs/report"
import PFHD from "./Components/AboutUs/PFHD"
import Gos from "./Components/AboutUs/gosexercise"
import Structure from "./Components/AboutUs/Structure"
import OpenShop from "./Components/AboutUs/openShop"
import Citizen from "./Components/AboutUs/citizen"
import Contacts from "./Components/AboutUs/contacts"
import NewsBlock from "./Components/News/News"
import WebsBlock from "./Components/News/webs"
import VideoPrice from "./Components/News/VideioPrice"
import MediaciaBase from "./Components/defaultPages/TOCOPY"
import IIVseros from "./Components/defaultPages/IIVseros"
import NG2023 from "./Components/defaultPages/NG"
import LS2023 from "./Components/defaultPages/NewsStyleLife"
import FindPage from "./Components/Header/Finder/FindPage"
import BlankPage from "./Components/defaultPages/Blank"
import WebsIsolation from "./Components/defaultPages/WebsIsolation"
import SuicPage from "./Components/defaultPages/SuicideRedemption"
import Vseros2 from "./Components/defaultPages/Vseros2"
import KSWarZone from "./Components/defaultPages/KSWarZone";
import HLAboutQuestions from "./Components/defaultPages/HLAboutQuestions";
import BestInclusiveSchool2022 from "./Components/defaultPages/BestInclusiveSchool2022";
import NSR2022 from "./Components/defaultPages/NaStoroneRebenka";
import HagueConventionsSeminar from "./Components/defaultPages/HagueConventionsSeminar";
import CompetitionOfSocializationOver from "./Components/defaultPages/CompetitionOfSocializationOver";
import PCOnTheSideOfTheChild from "./Components/defaultPages/PCOnTheSideOfTheChild";
import SecondMeetingOfSRAMS from "./Components/defaultPages/SecondMeetingOfSRAMS";
import ConferenceOfPEPSOS from "./Components/defaultPages/ConferenceOfPEPSOS";
import HumanitarianMission from "./Components/defaultPages/HumanitarianMission";
import ResultsBestCenterCompetition from "./Components/defaultPages/ResultsBestCenterCompetition";
import FourthOnlineConfDecadeOfChildhood from "./Components/defaultPages/FourthOnlineConfDecadeOfChildhood";
import FestivalOfPractitionersMasterForum from "./Components/defaultPages/FestivalOfPractitionersMasterForum";
import ResultsOfSecondMSRAMS from "./Components/defaultPages/ResultsOfSecondMSRAMS";
import ResultsAndProblemsOfEBA from "./Components/defaultPages/ResultsAndProblemsOfEBA";
import MoscowFourthDecade from "./Components/defaultPages/MoscowFourthDecade";
import SupportOfMediationServices2022 from "./Components/defaultPages/SupportOfMediationServices2022";
import CompetitionSelectionPlatforms from "./Components/defaultPages/CompetitionSelectionPlatforms";
import NewFunctionality from "./Components/defaultPages/NewFunctionality";
import StopHIVAIDS from "./Components/defaultPages/StopHIVAIDS";
import OrganizationSystemWork from "./Components/defaultPages/OrganizationSystemWork";
import ExpandChildPart from "./Components/defaultPages/ExpandChildPart";
import SSPSmolensk from "./Components/defaultPages/SSPSmolensk";
import DecadeOfChildhood from "./Components/defaultPages/DecadeOfChildhood";
import SummingUpResultsOTSOTC from "./Components/defaultPages/SummingUpResultsOTSOTC";
import ProtectionFromSexualViolence2022 from "./Components/defaultPages/ProtectionFromSexualViolence2022"
import FourthTeenagers360 from "./Components/defaultPages/FourthTeenagers360";
import PreventionOfTheSpreadHIV2022 from "./Components/defaultPages/PreventionOfTheSpreadHIV2022";
import SocializationTeenagersNewZone from "./Components/defaultPages/SocializationTeenagersNewZone";
import VebinarOsobennostiPPS from "./Components/defaultPages/VebinarOsobennostiPPS";
import MeetingHeadsGuardianship from "./Components/defaultPages/MeetingHeadsGuardianship";
import CommissionsOnJuvenileAffairs from "./Components/defaultPages/CommissionsOnJuvenileAffairs";
import StoryNamedWE from "./Components/defaultPages/StoryNamedWE";
import SupportEducationalInstitutions2022 from "./Components/defaultPages/SupportEducationalInstitutions2022";
import TeenageZoneFactory from "./Components/defaultPages/TeenageZoneFactory";
import EducationForDisabled from "./Components/defaultPages/EducationForDisabled";
import PreventionTobacco2022 from "./Components/defaultPages/PreventionTobacco2022";
import GrandOpeningTeenageSpaces from "./Components/defaultPages/GrandOpeningTeenageSpaces";
import SupportMediationSEI2022 from "./Components/defaultPages/SupportMediationSEI2022";
import WebinarWorkStudents from "./Components/defaultPages/WebinarWorkStudents";
import LifestyleHealth2022 from "./Components/defaultPages/LifestyleHealth2022";
import WPRS2022 from "./Components/defaultPages/WPRS2022";
import CompetitionForTheSelection2022 from "./Components/defaultPages/CompetitionForTheSelection2022";
import OFFF2022 from "./Components/defaultPages/OFFF2022";
import VectorsNewOpportunities from "./Components/defaultPages/VectorsNewOpportunities";
import ММСОEXPO2023 from "./Components/defaultPages/ММСОEXPO2023";
import StartASP2023 from "./Components/defaultPages/StartASP2023";
import DoNotCrossLine from "./Components/defaultPages/DoNotCrossLine";
import LifeStyle2021 from "./Components/defaultPages/LifeStyle2021";
import ReestrNecomOrg from "./Components/defaultPages/ReestrNecomOrg";
import ReestrNecomOrgNew from "./Components/defaultPages/ReestrNecomOrgNew";
import FeaturesConditions from "./Components/defaultPages/FeaturesConditions";
import MainMissionsDecade from "./Components/defaultPages/MainMissionsDecade";
import MonitoringMediacia from "./Components/defaultPages/MonitoringMediacia";
import WorkOrganizationVICH from "./Components/defaultPages/WorkOrganizationVICH";
import FilesValueNorm from "./Components/common/FilePage/Value_Norm"
import FilesValueMet from "./Components/common/FilePage/Value_Met"
import FilesValueInf from "./Components/common/FilePage/Value_Inf"
import FilesConceptMon from "./Components/common/FilePage/FileKonceptMonitoring"
import FilesPublicationsForms from "./Components/common/FilePage/FilesPublicationsForms";
import FilesPublicationsBeznadzor from "./Components/common/FilePage/FilesPublicationsBeznadzor";
import ResponsPerformers from "./Components/common/FilePage/ResponsPerformers";
import FilesAllRusCon from "./Components/common/FilePage/FilesAllRusCon";
import LentaRegPrac from "./Components/common/FilePage/LentaRegPrac";
import RegDocks from "./Components/common/FilePage/RegDocks";
import MetWay from "./Components/common/FilePage/MetWay";
import PopQuest from "./Components/common/FilePage/PopularQuestions";
import FeaturesOfTeenagers from "./Components/defaultPages/FeaturesOfTeenagers"
import RestrictionAndPunishment from "./Components/defaultPages/RestrictionAndPunishment"
import EightRules from "./Components/defaultPages/EightRules"
import ConsultationDeviationWebinar from "./Components/defaultPages/ConsultationDeviationWebinar"
import TheWayOfHooligan from "./Components/defaultPages/TheWayOfHooligan"
import CosmonauticsDay2023 from "./Components/defaultPages/CosmonauticsDay2023"
import IAmMentor2023 from "./Components/defaultPages/IAmMentor2023"
import SirotyTwentyThreeHouses from "./Components/defaultPages/SirotyTwentyThreeHouses"
import SupportMediationServicesMarch2023 from "./Components/defaultPages/SupportMediationServicesMarch2023"
import Saratov105 from "./Components/defaultPages/Saratov105"
import ImprovementEffectiveSystem from "./Components/common/FilePage/ImprovementEffectiveSystem";
import ManagingConc from "./Components/common/FilePage/ManagingConc";
import IMSBezn from "./Components/common/FilePage/IMSBezn";
import ImprovementOfRegulatory from "./Components/common/FilePage/ImprovementOfRegulatory";
import MASRRF from "./Components/common/FilePage/MASRRF";
import FilesSchoolTV from "./Components/common/FilePage/FilesSchoolTV";
import FilesInfoMaterialsForStudents from "./Components/common/FilePage/FilesInfoMaterialsForStudents";
import FilesVideoProfilaktik from "./Components/common/FilePage/FilesVideoProfilaktik";
import FilesAntiNarcotic from "./Components/common/FilePage/FilesAntiNarcotic";
import FilesAddictiveBehavior from "./Components/common/FilePage/FilesAddictiveBehavior";
import FilesArticlesPublication from "./Components/common/FilePage/FilesArticlesPublication";
import FileAdditionalInfo from "./Components/common/FilePage/FileAdditionalInfo";
import StrengtheningTiesCreativeExchange from "./Components/defaultPages/StrengtheningTiesCreativeExchange";
import NewProjectTheWayOfHooligan from "./Components/defaultPages/NewProjectTheWayOfHooligan";
import ETSCDTV from "./Components/defaultPages/ETSCDTV";
import VDCOrlenok from "./Components/defaultPages/VDCOrlenok"
import SupportOfMediationServicesJune2022 from "./Components/defaultPages/SupportOfMediationServicesJune2022";
import SupportOfMediationServicesMay2022 from "./Components/defaultPages/SupportOfMediationServicesMay2022";
import SupportOfMediationServicesApril2022 from "./Components/defaultPages/SupportOfMediationServicesApril2022";
import SupportOfMediationServicesMarch2022 from "./Components/defaultPages/SupportOfMediationServicesMarch2022";
import MediationStaffTraining from "./Components/defaultPages/MediationStaffTraining";
import MediatsiyaPage from "./Components/defaultPages/MediatsiyaPage"
import TerritoryOfMeanings2022 from "./Components/defaultPages/TerritoryOfMeanings2022";
import SecondSiberiaEuropeAsia from "./Components/defaultPages/SecondSiberiaEuropeAsia";
import FirstTeenagers360 from "./Components/defaultPages/FirstTeenagers360";
import MediationYouthFuture from "./Components/defaultPages/MediationYouthFuture";
import MediaciaFuturePers from "./Components/defaultPages/MediaciaFuturePers";
import TSUMVDC from "./Components/defaultPages/TSUMVDC";
import VideoMediaciaConflict from "./Components/defaultPages/VideoMediaciaConflict";
import SecondSSSPM from "./Components/defaultPages/SecondSSSPM";
import UniMediator from "./Components/defaultPages/UniMediator";
import OORK from "./Components/common/PageInfo/OORK";
import OAVVVD from "./Components/common/PageInfo/OAVVVD";
import OPVP from "./Components/common/PageInfo/OPVP";
import OOMDK from "./Components/common/PageInfo/OOMDK";
import EightVSPV from "./Components/common/PageInfo/EightVSPV";
import KDNIZP2020 from "./Components/common/PageInfo/KDNIZP2020";
import Plen2020 from "./Components/common/PageInfo/Plen2020";
import MasterSeven from "./Components/common/PageInfo/MasterSeven";
import Ploshad1 from "./Components/common/PageInfo/Ploshad1";
import Ploshad2 from "./Components/common/PageInfo/Ploshad2";
import Ploshad3 from "./Components/common/PageInfo/Ploshad3";
import FilesKDNNews from "./Components/common/FilePage/FilesKDNNews";
import News0305 from "./Components/defaultPages/News0305";
import VictoryDay2023 from "./Components/defaultPages/VictoryDay2023";
import FPF2021 from "./Components/common/FilePage/FPF2021";
import FPF2020 from "./Components/common/FilePage/FPF2020";
import FPF1718 from "./Components/common/FilePage/FPF1718";
import FPF1819 from "./Components/common/FilePage/FPF1819";
import ISNPRVP from "./Components/common/FilePage/ISNPRVP";
import ISNPRVP2 from "./Components/common/FilePage/ISNPRVP2";
import ISNPRVP3 from "./Components/common/FilePage/ISNPRVP3";
import ISNPRVP4 from "./Components/common/FilePage/ISNPRVP4";
import ISNPRVP5 from "./Components/common/FilePage/ISNPRVP5";
import TvoyoP from "./Components/common/FilePage/tvoyo-pravo";
import ChildDefence2022 from "./Components/common/PageInfo/ChildDefence2022";
import ChildDefence2021 from "./Components/common/PageInfo/ChildDefence2021";
import PlenSession2021 from "./Components/common/PageInfo/PlenSession2021";
import PsihBlag2021 from "./Components/common/PageInfo/PsihBlag2021";
import Section20211 from "./Components/common/PageInfo/Section20211";
import Section20212 from "./Components/common/PageInfo/Section20212";
import NewSecondVseros from "./Components/defaultPages/NewSecondVseros";
import NewSecondVserosPhoto from "./Components/defaultPages/NewSecondVserosPhoto";
import NewHistoryKDN from "./Components/common/PageInfo/NewHistoryKDN";
import PageInfoKDNMain from "./Components/common/PageInfo/PageInfoKDNMain";
import WinnerProtocol from "./Components/defaultPages/WinnerWinnerChickenDinner";
import Zased30032023 from "./Components/defaultPages/Zased30032023";
import IINomination from "./Components/defaultPages/IINomination";
import VICH2611 from "./Components/defaultPages/VICH2611";
import FilesAntiNarcoticParents from "./Components/common/FilePage/FilesAntiNarcoticParents";
import ValueRef from "./Components/common/FilePage/ValueRef";
import LifestylePosobie from "./Components/common/PageInfo/LifestylePosobie"
import RegionalExp from "./Components/common/PageInfo/RegionalExp";
import KurchenkoDrugs from "./Components/common/PageInfo/KurchenkoDrugs";
import VichMonitoring from "./Components/common/PageInfo/VichMonitoring";
import HigherSky from "./Components/common/PageInfo/HigherSky";
import SolovievaVICH from "./Components/common/PageInfo/SolovievaVICH";
import BelozerovaVICH from "./Components/common/PageInfo/BelozerovaVICH";
import EducationPage from "./Components/defaultPages/Education"
import News25062023 from "./Components/defaultPages/News25062023";
import News26062023 from "./Components/defaultPages/News26062023";
import News260523 from "./Components/defaultPages/News260523";
import Concurs from './Components/Concurs/Concurs';
import EduTest from './Components/Concurs/EduTest';
import News08022022 from "./Components/defaultPages/News08022022";
import News2102022 from "./Components/defaultPages/News2102022";
import News02032022 from "./Components/defaultPages/News02032022";
import News10032022 from "./Components/defaultPages/News10032022";
import News01042022 from "./Components/defaultPages/News01042022";
import News11042022 from "./Components/defaultPages/News11042022";
import News15042022 from "./Components/defaultPages/News15042022";
import News23032022 from "./Components/defaultPages/News23032022";
import News120422 from "./Components/defaultPages/News120422";
import News180422 from "./Components/defaultPages/News180422";
import News270422 from "./Components/defaultPages/News270422";
import News280422 from "./Components/defaultPages/News280422";
import News010522 from "./Components/defaultPages/News010522";
import News161224 from "./Components/defaultPages/News161224";
import News170522 from "./Components/defaultPages/News170522";
import News08052022 from "./Components/defaultPages/News08052022";
import News010622 from "./Components/defaultPages/News010622";
import News10622 from "./Components/defaultPages/News10622";
import News051023 from "./Components/defaultPages/News051023";
import News16062022 from "./Components/defaultPages/News16062022";
import News20062022 from "./Components/defaultPages/News20062022";
import News20062022Pskov from "./Components/defaultPages/News20062022Pskov";
import News24062022 from "./Components/defaultPages/News24062022";
import News240622 from "./Components/defaultPages/News240622";
import News040722 from "./Components/defaultPages/News040722";
import News08072022 from "./Components/defaultPages/News08072022";
import News120722 from "./Components/defaultPages/News120722";
import News01082022 from "./Components/defaultPages/News01082022";
import News010822 from "./Components/defaultPages/News010822";
import News110822 from "./Components/defaultPages/News110822";
import News17082022 from "./Components/defaultPages/News17082022";
import News08092022 from "./Components/defaultPages/News08092022";
import News140922 from "./Components/defaultPages/News140922";
import News14092022 from "./Components/defaultPages/News14092022";
import News22092022 from "./Components/defaultPages/News22092022";
import News29052023 from "./Components/defaultPages/News29052023";
import News310522 from "./Components/defaultPages/News310522";
import News310520222 from "./Components/defaultPages/News310520222";
import News08062023 from "./Components/defaultPages/News08062023";
import ChildDay2023 from "./Components/defaultPages/ChildDay2023";
import VserosZona from "./Components/defaultPages/VserosZona"
import FederalnyjCenter from "./Components/defaultPages/FederalnyjCenter"
import News05122019 from "./Components/defaultPages/News05122019";
import BehaviorPP from "./Components/common/PageInfo/BehaviorPP";
import SuvuNovember from "./Components/defaultPages/SuvuNovember";
import SuvuSeptember from "./Components/defaultPages/SuvuSeptember";
import Suvu12 from "./Components/defaultPages/Suvu12";
import SuvuGraph from "./Components/defaultPages/SuvuGraph";
import SuvuApril2021 from "./Components/defaultPages/SuvuApril2021";
import News22062023 from "./Components/defaultPages/News22062023";
import Putin2406 from "./Components/defaultPages/Putin2406";
import Putin24061 from "./Components/defaultPages/Putin24061";
import News29062023 from "./Components/defaultPages/News29062023";
import News03072023 from "./Components/defaultPages/News03072023";
import News04072023 from "./Components/defaultPages/News04072023";
import OSRPRV from "./Components/defaultPages/OSRPRV";
import VMT from "./Components/defaultPages/VMT";
import ParentResources from "./Components/defaultPages/ParentResources";
import News110723 from "./Components/defaultPages/News110723";
import PreventionOfChildMortality from "./Components/defaultPages/PreventionOfChildMortality";
import News13072023 from "./Components/defaultPages/News13072023";
import News170723 from "./Components/defaultPages/News170723";
import News1707232 from "./Components/defaultPages/News1707232";
import News190723 from "./Components/defaultPages/News190723";
import News200723 from "./Components/defaultPages/News200723";
import News210723 from "./Components/defaultPages/News210723";
import News030823 from "./Components/defaultPages/News030823";
import News210823 from "./Components/defaultPages/News210823";
import OgranichenieNakazanie from "./Components/defaultPages/OgranichenieNakazanie";
import ConsDANesov from "./Components/defaultPages/ConsDANesov";
import Osobennosti from "./Components/defaultPages/Osobennosti";
import ExamsWiStress from "./Components/defaultPages/ExamsWiStress";
import TerritoryWithout from "./Components/defaultPages/TerritoryWithout";
import LifeStyle2020 from "./Components/defaultPages/LifeStyle2020";
import News091023 from "./Components/defaultPages/News091023";
import News131023 from "./Components/defaultPages/News131023";
import NewMonitorings from "./Components/Mediacia/NewMonitorings";
import BezopasnostDetstva from "./Components/Behavior_Prevention/BezopasnostDetstva";
import TestMediacia from "./Components/Mediacia/TestMediacia";
import News191023 from "./Components/defaultPages/News191023";
import News1910232 from "./Components/defaultPages/News1910232";
import News201023 from "./Components/defaultPages/News201023";
import News231023 from "./Components/defaultPages/News231023";
import News311023 from "./Components/defaultPages/News311023";
import News031123 from "./Components/defaultPages/News031123";
import News101123 from "./Components/defaultPages/News101123";
import News201123 from "./Components/defaultPages/News201123";
import News231123 from "./Components/defaultPages/News231123";
import News271123 from "./Components/defaultPages/News271123";
import News281123 from "./Components/defaultPages/News281123";
import News2811232 from "./Components/defaultPages/News2811232";
import News2811233 from "./Components/defaultPages/News2811233";
import News241123 from "./Components/defaultPages/News241123";
import News291123 from "./Components/defaultPages/News291123";
import News041223 from "./Components/defaultPages/News041223";
import News111223 from "./Components/defaultPages/News111223";
import News131223 from "./Components/defaultPages/News131223";
import News141223 from "./Components/defaultPages/News141223";
import News1412232 from "./Components/defaultPages/News1412232";
import News151223 from "./Components/defaultPages/News151223";
import News191223 from "./Components/defaultPages/News191223";
import News211223 from "./Components/defaultPages/News211223";
import News2112232 from "./Components/defaultPages/News2112232";
import ChildDefence2023 from "./Components/common/PageInfo/ChildDefence2023";
import News271223 from "./Components/defaultPages/News271223";
import News281223 from "./Components/defaultPages/News281223";
import News090124 from "./Components/defaultPages/News090124";
import Value_Met1 from "./Components/common/FilePage/Value_Met1";
import Value_Met2 from "./Components/common/FilePage/Value_Met2";
import Value_Met3 from "./Components/common/FilePage/Value_Met3";
import Value_Met4 from "./Components/common/FilePage/Value_Met4";
import Value_Met5 from "./Components/common/FilePage/Value_Met5";
import Value_Met6 from "./Components/common/FilePage/Value_Met6";
import News290124 from "./Components/defaultPages/News290124";
import News010224 from "./Components/defaultPages/News010224";
import News060224 from "./Components/defaultPages/News060224";
import News190224 from "./Components/defaultPages/News190224";
import News220224 from "./Components/defaultPages/News220224";
import News280224 from "./Components/defaultPages/News280224";
import News010324 from "./Components/defaultPages/News010324";
import News160824 from "./Components/defaultPages/News160824";
import News040324 from "./Components/defaultPages/News040324";
import News060324 from "./Components/defaultPages/News060324";
import News120324 from "./Components/defaultPages/News120324";
import VideoMediacia from "./Components/Mediacia/VideoMediacia";
import VideoMediaciaParents from "./Components/Mediacia/VideoMediaciaParents";
import VideoMediaciaChildren from "./Components/Mediacia/VideoMediaciaChildren";
import VideoMediaciaPiece from "./Components/Mediacia/VideoMediaciaPiece";
import News180324 from "./Components/defaultPages/News180324";
import SUVUNavigator from "./Components/defaultPages/SUVUNavigator";
import News280324 from "./Components/defaultPages/News280324";
import News2803242 from "./Components/defaultPages/News2803242";
import NewSUVU from "./Components/SUVU/NewSUVU";
import News020424 from "./Components/defaultPages/News020424";
import News030424 from "./Components/defaultPages/News030424";
import News050424 from "./Components/defaultPages/News050424";
import News150424 from "./Components/defaultPages/News150424";
import News170424 from "./Components/defaultPages/News170424";
import News220424 from "./Components/defaultPages/News220424";
import News2204242 from "./Components/defaultPages/News2204242";
import News170221 from "./Components/defaultPages/News170221";
import News100321 from "./Components/defaultPages/News100321";
import News020421 from "./Components/defaultPages/News020421";
import News270421 from "./Components/defaultPages/News270421";
import News250521 from "./Components/defaultPages/News250521";
import News011121 from "./Components/defaultPages/News011121";
import News171221 from "./Components/defaultPages/News171221";
import News230424 from "./Components/defaultPages/News230424";
import News080524 from "./Components/defaultPages/News080524";
import News140523 from "./Components/defaultPages/News140523";
import Rules from "./Components/Mediacia/Rules";
import News210524 from "./Components/defaultPages/News210524";
import News240524 from "./Components/defaultPages/News240524";
import News250524 from "./Components/defaultPages/News250524";
import News2705242 from "./Components/defaultPages/News2705242";
import News280524 from "./Components/defaultPages/News280524";
import News2805242 from "./Components/defaultPages/News2805242";
import News290524 from "./Components/defaultPages/News290524";
import News2905242 from "./Components/defaultPages/News2905242";
import News300524 from "./Components/defaultPages/News300524";
import News3005242 from "./Components/defaultPages/News3005242";
import News0106242 from "./Components/defaultPages/News0106242";
import News030624 from "./Components/defaultPages/News030624";
import News040624 from "./Components/defaultPages/News040624";
import News0406242 from "./Components/defaultPages/News0406242";
import News0406243 from "./Components/defaultPages/News0406243";
import News110624 from "./Components/defaultPages/News110624";
import News120624 from "./Components/defaultPages/News120624";
import News140624 from "./Components/defaultPages/News140624";
import News170624 from "./Components/defaultPages/News170624";
import News200624 from "./Components/defaultPages/News200624";
import News220624 from "./Components/defaultPages/News220624";
import News260624 from "./Components/defaultPages/News260624";
import News270624 from "./Components/defaultPages/News270624";
import News2706242 from "./Components/defaultPages/News2706242";
import News2706243 from "./Components/defaultPages/News2706243";
import News280624 from "./Components/defaultPages/News280624";
import News030724 from "./Components/defaultPages/News030724";
import News160724 from "./Components/defaultPages/News160724";
import News260724 from "./Components/defaultPages/News260724";
import News260724Reg from "./Components/defaultPages/News260724Reg";
import News150824 from "./Components/defaultPages/News150824";
import News210824 from "./Components/defaultPages/News210824";
import News260824 from "./Components/defaultPages/News260824";
import News280824 from "./Components/defaultPages/News280824";
import News090924 from "./Components/defaultPages/News090924";
import News260924 from "./Components/defaultPages/News260924";
import News011024 from "./Components/defaultPages/News011024";
import News091024 from "./Components/defaultPages/News091024";
import {useMediaQuery} from "react-responsive";
import MobileHeader from "./Components/Header/HeaderMobile";
import News101024 from "./Components/defaultPages/News101024";
import News111024 from "./Components/defaultPages/News111024";
import News181024 from "./Components/defaultPages/News181024";
import News241024 from "./Components/defaultPages/News241024";
import News251024 from "./Components/defaultPages/News251024";
import News291024 from "./Components/defaultPages/News291024";
import News061124 from "./Components/defaultPages/News061124";
import News121124 from "./Components/defaultPages/News121124";
import News151124 from "./Components/defaultPages/News151124";
import New201124 from "./Components/defaultPages/News201124";
import News211124 from "./Components/defaultPages/News211124";
import News221124 from "./Components/defaultPages/News221124";
import News291124 from "./Components/defaultPages/News291124";
import News2911242 from "./Components/defaultPages/News2911242";
import News021224 from "./Components/defaultPages/News021224";
import News121224 from "./Components/defaultPages/News121224";
import News1212242 from "./Components/defaultPages/News1212242";
import ChildDefence2024 from "./Components/common/PageInfo/ChildDefence2024";
import Snowfall from "react-snowfall";
import News281224 from "./Components/defaultPages/News281224";
import News2812242 from "./Components/defaultPages/News2812242";



function App() {
    const [find, setFind] = useState([])
    const [styl, setStyle] = useState("App")
    const [isConcurs, setPageConcurs] = useState(true)
    const isSmall = useMediaQuery({ query: "(max-width: 1200px)" });
    if (isConcurs) {
        return (<BrowserRouter>

                <div className={styl}>
                    {/*<Snowfall snowflakeCount={250} style={{height: "100vh", width: "90vw", position: "fixed"}}>*/}
                    {/*</Snowfall>*/}
                    {!isSmall && <Header find={find} setFind={setFind} styl={styl} setStyle={setStyle}/>}
                    {isSmall && <MobileHeader/>}
                    <div class='body'>
                        <Routes>
                            <Route exact={true} path={"/"} element={<Main find={find} setFind={setFind}/>}/>
                            {/*<Route path="/mediacia" element={<Mediacia/>}/>*/}
                            <Route path="/mediacia" element={<TestMediacia/>}/>
                            <Route path="/vserosconc" element={<Vseros/>}/>

                            <Route path="/monitoringi" element={<NewMonitorings/>}/>
                            <Route path="/bezopasnost-detstva" element={<BezopasnostDetstva/>}/>


                            <Route path="/find" element={<FindPage/>}/>
                            <Route path="/blank" element={<BlankPage/>}/>
                            <Route path="/news/i-am-mentor-2023" element={<IAmMentor2023/>}/>
                            <Route path="/news/siroty-twent-three-houses" element={<SirotyTwentyThreeHouses/>}/>
                            <Route path="/news/support-mediation-services-march-2023"
                                   element={<SupportMediationServicesMarch2023/>}/>
                            <Route path="/saratov-105" element={<Saratov105/>}/>
                            <Route path="/metodicheskie-razrabotki/" element={<MetodolDev/>}/>
                            {/*<Route path="/monitoringi" element={<Monitorings/>}/>*/}
                            <Route path="/kdn" element={<PageInfoKDNMain/>}/>
                            <Route path="/suvu" element={<PageFRCSUVU/>}/>
                            <Route path="/educationpage" element={<EducationPage/>}/>
                            <Route path="/spec-suvu/news-suvu/" element={<FilesSuvNews/>}/>
                            <Route path="/suvuarchive" element={<PageArhSUVU/>}/>
                            <Route path="/meropr" element={<CalendarPage/>}/>
                            <Route path="/spec-suvu/ludi-fakti-sobitiya/" element={<FilesSuvuPeople/>}/>
                            <Route path="/responsible-behavior" element={<PageInfo/>}/>
                            <Route path="/meshdunarodSotrudnichestvo" element={<PageMeshdSotr/>}/>
                            <Route path="/value-of-life" element={<PagePriceLife/>}/>
                            <Route path="/infSec" element={<PagePriceLifeInfSec/>}/>
                            <Route path="/secWay" element={<PagePriceLifeSecWay/>}/>
                            <Route path="/conception" element={<PageKonceps/>}/>
                            <Route path="/profilaktika-deviantnogo-povedeniya" element={<Behavior/>}/>
                            <Route path="/news/strengthening-ties-creative-exchange"
                                   element={<StrengtheningTiesCreativeExchange/>}/>
                            <Route path="/education" element={<Education/>}/>
                            <Route path="/webinar" element={<Webinar/>}/>
                            <Route path="/2vseros" element={<IIVseros/>}/>
                            <Route path="/news/NG2023" element={<NG2023/>}/>
                            <Route
                                path="/responsible-behavior/elektronnoe-naglyadnoe-posobie-po-rabote-s-obuchayushhimisya-stil-zhizni-zdorove"
                                element={<LifestylePosobie/>}/>
                            <Route path="/news/LS" element={<LS2023/>}/>
                            <Route path="/maindir" element={<Directions/>}/>
                            <Route path="/reports" element={<Reports/>}/>
                            <Route path="/pfhd" element={<PFHD/>}/>
                            <Route path="/news" element={<NewsBlock/>}/>
                            <Route path="/mediacia/base" element={<MediaciaBase/>}/>
                            <Route path="/webs" element={<WebsBlock/>}/>
                            <Route path="/spec-value-of-life/video-price" element={<VideoPrice/>}/>
                            <Route path="/gosexercise" element={<Gos/>}/>
                            <Route path="/structure" element={<Structure/>}/>
                            <Route path="/openshops" element={<OpenShop/>}/>
                            <Route path="/citsendata" element={<Citizen/>}/>
                            <Route path="/contacts" element={<Contacts/>}/>
                            <Route path="/cuic" element={<SuicPage/>}/>
                            <Route path="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida"
                                   element={<FilesSpid/>}/>
                            <Route path="/spec-materials-category/informatsionnye-materialy-dlya-roditelej"
                                   element={<FilesForParents/>}/>
                            <Route path="/files-inf-mat" element={<FilesInfMatireal/>}/>
                            <Route path="/spec-materials-category/normativno-pravovye-dokumenty" element={<FilesNPD/>}/>
                            <Route path="/spec-materials-category/materialy-dlya-podrostkov-i-molodezhi"
                                   element={<FilesChild/>}/>
                            <Route path="/spec-materials-category/luchshie-praktiki" element={<FilesEffective/>}/>
                            <Route path="/spec-materials-category/federalnye-zakony" element={<FilesFZ/>}/>
                            <Route path="/ii-vserossijskoe-soveshhanie-shkolnyh-sluzhb-primireniya-i-mediatsii-2/"
                                   element={<NewSecondVseros/>}/>
                            <Route path="/fotootchet" element={<NewSecondVserosPhoto/>}/>
                            <Route path="/news/01062023" element={<ChildDay2023/>}/>
                            <Route path="/news/01062024" element={<ChildDay2023/>}/>
                            <Route path="/news/the-way-of-hooligan" element={<TheWayOfHooligan/>}/>
                            <Route path="/news/cosmonautics-day-2023" element={<CosmonauticsDay2023/>}/>
                            <Route path="/files-ustav" element={<FilesUstav/>}/>
                            <Route path="/antimonopolnoe-zakonodatelstvo" element={<FilesAntimonopo/>}/>
                            <Route path="/files-anticor" element={<FilesAnticor/>}/>
                            <Route path="/files-income" element={<FilesIncome/>}/>
                            <Route path="/spec-materials-category/strategii" element={<FilesStrats/>}/>
                            <Route path="/spec-materials-category/meropriyatiya" element={<FilesMer/>}/>
                            <Route path="/spec-materials-category/kontseptsii" element={<FilesConceps/>}/>
                            <Route path="/spec-conception/programma-monitoringa-realizatsii-kontseptsii"
                                   element={<FilesConceptMon/>}/>
                            <Route path="/spec-suvu/normativno-pravovye-dokumenty/" element={<FilesSuvuNorm/>}/>
                            <Route path="/spec-suvu/informatsionno-analiticheskie-materialy/"
                                   element={<FilesSuvuMet/>}/>
                            <Route path="/responsible-behavior/monitoring-federalnyh-i-regionalnyh-programm"
                                   element={<VichMonitoring/>}/>
                            <Route path="/responsible-behavior/hudozhestvennyj-film-vyshe-neba" element={<HigherSky/>}/>
                            <Route
                                path="/responsible-behavior/soloveva-n-v-sotsialno-psihologicheskoe-soprovozhdenie-semej-vospityvayushhih-vich-infitsirovannyh-detej"
                                element={<SolovievaVICH/>}/>
                            <Route
                                path="/responsible-behavior/belozerova-m-v-rol-nekommercheskih-organizatsij-v-profilaktike-vich-infektsii"
                                element={<BelozerovaVICH/>}/>
                            <Route path="/spec-suvu/meropriyatiya/" element={<FilesSuvuVseros/>}/>
                            <Route path="/news/260520233" element={<News260523/>}/>
                            <Route path="/spec-value-of-life/normativno-pravovye-materialy"
                                   element={<FilesValueNorm/>}/>
                            <Route path="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
                                   element={<FilesValueMet/>}/>
                            <Route path="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-1"
                                   element={<Value_Met1/>}/>
                            <Route path="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-2"
                                   element={<Value_Met2/>}/>
                            <Route path="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-3"
                                   element={<Value_Met3/>}/>
                            <Route path="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-4"
                                   element={<Value_Met4/>}/>
                            <Route path="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-5"
                                   element={<Value_Met5/>}/>
                            <Route path="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-6"
                                   element={<Value_Met6/>}/>
                            <Route path="/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
                                   element={<FilesValueInf/>}/>
                            <Route path="/kdnivents" element={<FilesKDNMer/>}/>
                            <Route path="/kdnHistory" element={<FilesKDNHis/>}/>
                            <Route path="/kdnGos" element={<FilesKDNGos/>}/>
                            <Route path="/kdnnews" element={<FilesKDNNews/>}/>
                            <Route path="/kdnDev" element={<FilesKDNDev/>}/> 
                            <Route path="/kdnRights" element={<FilesKDNRights/>}/>
                            <Route path="/behRead" element={<FilesKDNRead/>}/>
                            <Route
                                path="/responsible-behavior/artamonova-e-g-regionalnyj-opyt-deyatelnosti-pedagogov-psihologov-obrazovatelnyh-organizatsij-po-rannemu-vyyavleniyu-i-profilaktike-addiktivnogo-i-deviantnogo-povedeniya-nesovershennoletnih"
                                element={<RegionalExp/>}/>
                            <Route
                                path="/responsible-behavior/kurchenko-e-v-profilaktika-narkomanii-sredi-podrostkov-posredstvom-formirovaniya-sistemy-duhovno-nravstvennyh-orientirov"
                                element={<KurchenkoDrugs/>}/>
                            <Route path="/kdnInternational" element={<FilesKDNInt/>}/>
                            <Route path="/kdnActiveQ" element={<FilesKDNActQ/>}/>
                            <Route path="/kdnContacts" element={<FilesKDNContacts/>}/>
                            <Route path="/kdnInfoForm" element={<FilesKDNInfo/>}/>
                            <Route path="/kdnReestr" element={<FilesKDNReestr/>}/>
                            <Route path="/files-addect-behavior" element={<FilesAddectBehavior/>}/>
                            <Route path="/regDocks" element={<RegDocks/>}/>
                            <Route path="/news/IINomination" element={<IINomination/>}/>
                            <Route path="/news/etscdtv" element={<ETSCDTV/>}/>
                            <Route path="/news/unimediator" element={<UniMediator/>}/>
                            <Route path="/news/victory-day-2023" element={<VictoryDay2023/>}/>
                            <Route path="/news/circulartable0806" element={<News08062023/>}/>
                            <Route path="/news/vseros-res" element={<VserosZona/>}/>
                            <Route path="/news0305" element={<News0305/>}/>
                            <Route path="/met" element={<MetWay/>}/>
                            <Route path="/tvoyo-pravo" element={<TvoyoP/>}/>
                            <Route path="/improvement-of-regulatory" element={<ImprovementOfRegulatory/>}/>
                            <Route path="/improvement-effective-system" element={<ImprovementEffectiveSystem/>}/>
                            <Route path="/federalnyj-tsentr-razvitiya-programm-sotsializatsii-podrostkov/"
                                   element={<FederalnyjCenter/>}/>
                            <Route path="/ims-bezn" element={<IMSBezn/>}/>
                            <Route path="/managing-conc" element={<ManagingConc/>}/>
                            <Route path="/sptfaq" element={<PopQuest/>}/>
                            <Route path="/spec-materials-category/poleznye-ssylki-goryachie-linii"
                                   element={<BehHelphul/>}/>
                            <Route path="/school-behavior" element={<BehSchool/>}/>
                            <Route path="/mediacia/reestr-2020-tso-2/" element={<ReestrNecomOrg/>}/>
                            <Route path="/mediacia/reestr-2023/" element={<ReestrNecomOrgNew/>}/>
                            <Route path="/news/new-project-the-way-of-hooligan"
                                   element={<NewProjectTheWayOfHooligan/>}/>
                            <Route path="/improvement-of-regulatory" element={<ImprovementOfRegulatory/>}/>
                            <Route path="/improvement-effective-system" element={<ImprovementEffectiveSystem/>}/>
                            <Route path="/ims-bezn" element={<IMSBezn/>}/>
                            <Route
                                path="/spec-materials-category/materialy-antinarkoticheskogo-soderzhaniya-razrabotannye-v-subektah-rossijskoj-federatsii-luchshie-praktiki"
                                element={<MASRRF/>}/>
                            <Route path="/files-school-tv" element={<FilesSchoolTV/>}/>
                            <Route path="/spec-materials-category/informatsionnye-materialy-dlya-obuchayushhihsya"
                                   element={<FilesInfoMaterialsForStudents/>}/>
                            <Route
                                path="/spec-materials-category/videoroliki-po-formirovaniyu-navykov-protivostoyaniya-riskam-addiktivnogo-povedeniya-i-bezopasnogo-zdorovogo-obraza-zhizni"
                                element={<FilesVideoProfilaktik/>}/>
                            <Route
                                path="/spec-materials-category/metodicheskie-materialy-po-profilaktike-addiktivnogo-povedeniya"
                                element={<FilesAddictiveBehavior/>}/>
                            <Route path="/spec-materials-category/stati-i-publikatsii"
                                   element={<FilesArticlesPublication/>}/>
                            <Route path="/spec-materials-category/inye-reglamentiruyushhie-dokumenty"
                                   element={<FileAdditionalInfo/>}/>
                            <Route path="/zasedanie30032023" element={<Zased30032023/>}/>
                            <Route path="/news/04072023" element={<News04072023/>}/>
                            <Route path="/aboutUs" element={<AboutUs/>}/>
                            <Route path="/hotline1" element={<Hotline1/>}/>
                            <Route path="/hotline2" element={<Hotline2/>}/>
                            <Route path="/hotline3" element={<Hotline3/>}/>
                            <Route path="/education/registration" element={<Hotline4/>}/>
                            <Route path="/education/registrationPMPK" element={<Hotline5/>}/>
                            <Route path="/education/registrationOpeca" element={<Hotline6/>}/>
                            <Route path="/education/feedback" element={<Hotline7/>}/>
                            <Route path="/education/sendsertificate" element={<Hotline8/>}/>
                            <Route path="/psycho" element={<Psycho/>}/>
                            <Route path="/news/vseros2" element={<Vseros2/>}/>
                            <Route path="/spec-conception/2021-god/" element={<FPF2021/>}/>
                            <Route path="/spec-conception/2020-god" element={<FPF2020/>}/>
                            <Route path="/spec-conception/2018-19-uchebnyj-god" element={<FPF1819/>}/>
                            <Route path="/spec-conception/2017-18-uchebnyj-god" element={<FPF1718/>}/>
                            <Route path="/isnprvp" element={<ISNPRVP/>}/>
                            <Route path="/news/31052022" element={<News310522/>}/>
                            <Route path="/isnprvp2" element={<ISNPRVP2/>}/>
                            <Route path="/isnprvp3" element={<ISNPRVP3/>}/>
                            <Route path="/isnprvp4" element={<ISNPRVP4/>}/>
                            <Route path="/isnprvp5" element={<ISNPRVP5/>}/>
                            <Route path="/conception/2022" element={<ChildDefence2022/>}/>
                            <Route path="/conception/2024" element={<ChildDefence2024/>}/>
                            <Route path="/conception/2023" element={<ChildDefence2023/>}/>
                            <Route path="/conception/2021" element={<ChildDefence2021/>}/>
                            <Route
                                path="/webinars/vserossijskij-vebinar-dlya-pedagogov-zamestitelej-rukovoditelej-obrazovatelnyh-organizatsij-po-metodicheskim-voprosam-kompleksnogo-podhoda-k-formirovaniyu-kultury-zdorovogo-obraza-zhizni-u-detej-i-pod"
                                element={<VICH2611/>}/>
                            <Route path="/conception/plenarnaya-sessiya" element={<PlenSession2021/>}/>
                            <Route
                                path="/conception/panelnaya-diskussiya-psihologicheskoe-blagopoluchie-kak-osnova-bezopasnosti-detej-i-profilaktiki-podrostkovoj-deviatsii"
                                element={<PsihBlag2021/>}/>
                            <Route
                                path="/conception/sektsiya-1-bezopasnost-detstva-sotsialnye-praktiki-pomoshhi-rebenku-i-ego-seme"
                                element={<Section20211/>}/>
                            <Route
                                path="/conception/sektsiya-2-lichnost-i-sotsium-profilaktika-asotsialnogo-povedeniya-detej-i-podrostkov"
                                element={<Section20212/>}/>
                            <Route path="/webinars/spetsifika-preodoleniya-stressa-v-usloviyah-samoizolyatsii"
                                   element={<WebsIsolation/>}/>
                            <Route path="/news/kswarzone" element={<KSWarZone/>}/>
                            <Route path="/news/TSUMVDC" element={<TSUMVDC/>}/>
                            <Route path="/news/hlaq" element={<HLAboutQuestions/>}/>
                            <Route path="/news/best-inclusive-school-2022" element={<BestInclusiveSchool2022/>}/>
                            <Route path="/news/nsr-2022" element={<NSR2022/>}/>
                            <Route path="/news/hague-conventions-seminar" element={<HagueConventionsSeminar/>}/>
                            <Route path="/news/competition-of-socialization-over"
                                   element={<CompetitionOfSocializationOver/>}/>
                            <Route path="/news/pc-on-the-side-of-the-child" element={<PCOnTheSideOfTheChild/>}/>
                            <Route path="/news/winner-protocol" element={<WinnerProtocol/>}/>
                            <Route path="/news/second-meeting-of-srams" element={<SecondMeetingOfSRAMS/>}/>
                            <Route
                                path="/spec-materials-category/materialy-antinarkoticheskogo-soderzhaniya-razrabotannye-v-subektah-rossijskoj-federatsii/"
                                element={<FilesAntiNarcotic/>}/>
                            <Route path="/news/conference-of-pepsos" element={<ConferenceOfPEPSOS/>}/>
                            <Route path="/news/humanitarian-mission" element={<HumanitarianMission/>}/>
                            <Route path="/spec-materials-category/metodicheskie-materialy-dlya-spetsialistov/"
                                   element={<FilesExperts/>}/>
                            <Route path="/news/results-best-center-competition-2022"
                                   element={<ResultsBestCenterCompetition/>}/>
                            <Route path="/news/fourth-online-conf-decade-of-childhood"
                                   element={<FourthOnlineConfDecadeOfChildhood/>}/>
                            <Route path="/news/festival-of-practitioners-master-forum"
                                   element={<FestivalOfPractitionersMasterForum/>}/>
                            <Route path="/news/results-of-second-msrams" element={<ResultsOfSecondMSRAMS/>}/>
                            <Route path="/news/results-and-problems-of-eba" element={<ResultsAndProblemsOfEBA/>}/>
                            <Route path="/news/22062023" element={<News22062023/>}/>
                            <Route path="/mediacia/vcdorlenok" element={<VDCOrlenok/>}/>
                            <Route path="/news/support-mediation-services-june-2022"
                                   element={<SupportOfMediationServicesJune2022/>}/>
                            <Route path="/news/support-mediation-services-may-2022"
                                   element={<SupportOfMediationServicesMay2022/>}/>
                            <Route path="/news/support-mediation-services-april-2022"
                                   element={<SupportOfMediationServicesApril2022/>}/>
                            <Route path="/news/support-mediation-services-march-2022"
                                   element={<SupportOfMediationServicesMarch2022/>}/>
                            <Route path="/news/260520231" element={<News25062023/>}/>
                            <Route path="/news/260520232" element={<News26062023/>}/>
                            <Route path="/news/mediation-staff-training" element={<MediationStaffTraining/>}/>
                            <Route path="/mediatsiya_page" element={<MediatsiyaPage/>}/>
                            <Route path="/news/territory-of-meanings-2022" element={<TerritoryOfMeanings2022/>}/>
                            <Route path="/oork" element={<OORK/>}/>
                            <Route path="/oavvvd" element={<OAVVVD/>}/>
                            <Route path="/opvp" element={<OPVP/>}/>
                            <Route path="/oomdk" element={<OOMDK/>}/>
                            <Route path="/eight-vspv" element={<EightVSPV/>}/>
                            <Route path="/kdnzip2020" element={<KDNIZP2020/>}/>
                            <Route path="/plen2020" element={<Plen2020/>}/>
                            <Route path="/masterseven" element={<MasterSeven/>}/>
                            <Route path="/ploshad1" element={<Ploshad1/>}/>
                            <Route path="/ploshad2" element={<Ploshad2/>}/>
                            <Route path="/ploshad3" element={<Ploshad3/>}/>
                            <Route path="/second-siberia-europe-asia" element={<SecondSiberiaEuropeAsia/>}/>
                            <Route path="/news/first-teenagers-360" element={<FirstTeenagers360/>}/>
                            <Route path="/news/mediation-youth-future" element={<MediationYouthFuture/>}/>
                            <Route path="/news/mediacia-future-pers" element={<MediaciaFuturePers/>}/>
                            <Route path="/news/video-mediacia-conflict" element={<VideoMediaciaConflict/>}/>
                            <Route path="/secondssspm" element={<SecondSSSPM/>}/>
                            <Route path="/spec-conception/formy-monitoringa-realizatsii-kontseptsii"
                                   element={<FilesPublicationsForms/>}/>
                            <Route path="/spec-conception/plan-meropriyatij-kontseptsii/"
                                   element={<FilesPublicationsBeznadzor/>}/>
                            <Route path="/spec-conception/otvetstvennye-ispolniteli" element={<ResponsPerformers/>}/>
                            <Route path="/spec-conception/vserossijskie-konferentsii" element={<FilesAllRusCon/>}/>
                            <Route path="/spec-conception/lenta-pactic/" element={<LentaRegPrac/>}/>
                            <Route
                                path="/news/soporovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-iyune-2022-goda"
                                element={<SupportOfMediationServicesJune2022/>}/>
                            <Route
                                path="/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-mae-2022-goda"
                                element={<SupportOfMediationServicesMay2022/>}/>
                            <Route
                                path="/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-aprele-2022-goda"
                                element={<SupportOfMediationServicesApril2022/>}/>
                            <Route path="/news/i-mezhdunarodnyj-festival-mediatsiya-molodost-budushhee"
                                   element={<MediationYouthFuture/>}/>
                            <Route path="/news/soprovozhdenie-sluzhb-mediatsii-suvu-v-marte-3"
                                   element={<SupportOfMediationServicesMarch2022/>}/>
                            <Route path="/news/tematicheskaya-smena-yunyj-mediator-v-vdts-orlyonok-v-2022-god"
                                   element={<UniMediator/>}/>
                            <Route
                                path="/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-iyune-2022-goda"
                                element={<SupportOfMediationServicesJune2022/>}/>
                            <Route path="/kdnHistory" element={<NewHistoryKDN/>}/>
                            <Route path="/suvu/soprovozhdenie-sluzhb-mediatsii-suvu-v-noyabre-i-dekabre"
                                   element={<SuvuNovember/>}/>
                            <Route path="/suvu/soprovozhdenie-sluzhb-mediatsii-suvu-v-sentyabre-oktyabre"
                                   element={<SuvuSeptember/>}/>
                            <Route
                                path="/suvu/12-maya-sostoyalsya-obuchayushhij-vebinar-kotoryj-dal-start-monitoringu-spetsialnyh-uslovij-soderzhaniya-nesovershennoletnih-bezopasnosti-deyatelnosti-uchrezhdenij"
                                element={<Suvu12/>}/>
                            <Route
                                path="/suvu/ministerstvom-prosveshheniya-rossijskoj-federatsii-utverzhden-grafik-provedeniya-podvedomstvennymi-spetsialnymi-uchebno-vospitatelnymi-uchrezhdeniyami-dalee-suvu-obshhestvenno-znachimyh-meropriyatij-2"
                                element={<SuvuGraph/>}/>
                            <Route
                                path="/suvu/16-aprelya-2021-goda-sostoyalos-vserossijskoe-soveshhanie-po-voprosam-organizatsii-deyatelnosti-spetsialnyh-uchebno-vospitatelnyh-uchrezhdenij-suvu-na-temu-resursy-professionalnogo-obrazovaniya-v-reso"
                                element={<SuvuApril2021/>}/>
                            <Route path="/news/podvedeny-itogi-vserossijskogo-konkursa-dobroshkola"
                                   element={<News05122019/>}/>
                            <Route
                                path="/responsible-behavior/psihologo-pedagogicheskie-aspekty-formirovaniya-tsennosti-zdorovya-kultury-zdorovogo-i-bezopasnogo-obraza-zhizni-v-sisteme-obrazovaniya-sbornik-nauchno-metodicheskih-materialov"
                                element={<BehaviorPP/>}/>
                            <Route path="/news/29062023" element={<News29062023/>}/>
                            <Route path="/news/03072023" element={<News03072023/>}/>
                            <Route path="/news/moscow-fourth-decade" element={<MoscowFourthDecade/>}/>
                            <Route path="/news/competition-selection-platforms"
                                   element={<CompetitionSelectionPlatforms/>}/>
                            <Route path="/news/support-of-mediation-services-2022"
                                   element={<SupportOfMediationServices2022/>}/>
                            <Route path="/news/summing-up-results-otsotc" element={<SummingUpResultsOTSOTC/>}/>
                            <Route
                                path="/webinars/obuchayushhij-vebinar-funktsional-obnovlennogo-portala-desyatiletiya-detstva"
                                element={<NewFunctionality/>}/>
                            <Route
                                path="/webinars/29-noyabrya-2021-goda-v-ramkah-provedeniya-vserossijskoj-aktsii-stop-vich-spid-sostoyalsya-vebinar-dlya-pedagogicheskih-rabotnikov-po-voprosam-profilaktiki-vich-infektsii-sredi-obuchayushhihsya"
                                element={<StopHIVAIDS/>}/>
                            <Route
                                path="/webinars/vebinar-po-teme-organizatsiya-sistemnoj-raboty-po-profilaktike-rannego-vovlecheniya-v-nezakonnoe-potreblenie-narkoticheskih-sredstv-i-psihotropnyh-veshhestv-obuchayushhihsya-obrazovatelnyh-organizatsi"
                                element={<OrganizationSystemWork/>}/>
                            <Route
                                path="/webinars/rasshirenie-mehanizmov-uchastiya-detej-i-podrostkov-v-prinyatii-reshenij-zatragivayushhih-ih-interesy-ucheta-ih-mneniya-po-voprosam-v-sfere-detstva-na-federalnom-regionalnom-i-munitsipalnom-urovnyah"
                                element={<ExpandChildPart/>}/>
                            <Route
                                path="/webinars/sotsialno-znachimye-proekty-smolenskoj-oblasti-realizuemye-v-ramkah-plana-osnovnyh-meropriyatij-provodimyh-v-ramkah-desyatiletiya-detstva"
                                element={<SSPSmolensk/>}/>
                            <Route path="/webinars/31235" element={<DecadeOfChildhood/>}/>
                            <Route
                                path="/webinars/osobennosti-podrostkovogo-vozrasta-s-tochki-zreniya-psihologii-sotsiologii-i-etologii-podrostkovaya-deviantnost-2"
                                element={<FeaturesOfTeenagers/>}/>
                            <Route
                                path="/webinars/8-pravil-bezopasnogo-obshheniya-s-podrostkami-kak-roditelyam-reagirovat-na-trudnoe-povedenie-rebenka"
                                element={<EightRules/>}/>
                            <Route
                                path="/webinars/konsultirovanie-po-voprosam-detsko-roditelskih-otnoshenij-deviantnogo-povedeniya-nesovershennoletnih-sostoyalos-29-aprelya-2020-g"
                                element={<ConsultationDeviationWebinar/>}/>
                            <Route path="/news/protection-from-sexual-violence-2022"
                                   element={<ProtectionFromSexualViolence2022/>}/>
                            <Route path="/news/fourth-teenagers-360" element={<FourthTeenagers360/>}/>
                            <Route path="/news/prevention-of-the-spread-hiv-2022"
                                   element={<PreventionOfTheSpreadHIV2022/>}/>
                            <Route path="/news/socialization-teenagers-new-zone"
                                   element={<SocializationTeenagersNewZone/>}/>
                            <Route path="/news/vebinar-osobennosti-pps" element={<VebinarOsobennostiPPS/>}/>
                            <Route path="/news/meeting-heads-guardianship" element={<MeetingHeadsGuardianship/>}/>
                            <Route path="/news/commissions-on-juvenile-affairs"
                                   element={<CommissionsOnJuvenileAffairs/>}/>
                            <Route path="/news/story-named-we" element={<StoryNamedWE/>}/>
                            <Route path="/news/support-educational-institutions-2022"
                                   element={<SupportEducationalInstitutions2022/>}/>
                            <Route path="/news/teenage-zone-factory" element={<TeenageZoneFactory/>}/>
                            <Route path="/news/education-for-disabled" element={<EducationForDisabled/>}/>
                            <Route path="/news/prevention-tobacco-2022" element={<PreventionTobacco2022/>}/>
                            <Route path="/news/grand-opening-teenage-spaces" element={<GrandOpeningTeenageSpaces/>}/>
                            <Route path="/news/support-mediation-sei-2022" element={<SupportMediationSEI2022/>}/>
                            <Route path="/news/webinar-work-students" element={<WebinarWorkStudents/>}/>
                            <Route path="/news/lifestyle-health-2022" element={<LifestyleHealth2022/>}/>
                            <Route path="/news/wprs-2022" element={<WPRS2022/>}/>
                            <Route path="/news/competition-for-the-selection-2022"
                                   element={<CompetitionForTheSelection2022/>}/>
                            <Route path="/news/offf-2022" element={<OFFF2022/>}/>
                            <Route path="/news/vectors-new-opportunities" element={<VectorsNewOpportunities/>}/>
                            <Route path="/news/mmco-expo-2023" element={<ММСОEXPO2023/>}/>
                            <Route path="/news/start-asp-2023" element={<StartASP2023/>}/>
                            <Route
                                path="/news/nachinaetsya-priem-zayavok-dlya-uchastiya-vo-vserossijskom-konkurse-programm-sotsializatsii-podrostkov-13-marta-2023/"
                                element={<StartASP2023/>}/>
                            <Route path="/news/do-not-cross-line" element={<DoNotCrossLine/>}/>
                            <Route
                                path="/webinars/o-provedenii-vserossijskogo-konkursa-sotsialnoj-reklamy-stil-zhizni-zdorove-2021"
                                element={<LifeStyle2021/>}/>
                            <Route
                                path="/webinars/osobennosti-opredeleniya-spetsialnyh-uslovij-pri-prohozhdenii-gosudarstvennoj-itogovoj-attestatsii-obuchayushhimisya-s-ogranichennymi-vozmozhnostyami-zdorovya-i-invalidnostyu"
                                element={<FeaturesConditions/>}/>
                            <Route path="/webinars/klyuchevye-zadachi-i-mehanizmy-realizatsii-desyatiletiya-detstva"
                                   element={<MainMissionsDecade/>}/>
                            <Route
                                path="/webinars/o-provedenii-monitoringa-sluzhb-mediatsii-i-primireniya-na-territorii-subektov-rossijskoj-federatsii"
                                element={<MonitoringMediacia/>}/>
                            <Route
                                path="/webinars/organizatsiya-raboty-po-profilaktike-rasprostraneniya-vich-infektsii-i-formirovanie-kultury-zdorovogo-obraza-zhizni-u-detej-i-podrostkov"
                                element={<WorkOrganizationVICH/>}/>
                            <Route path="/reestrNecomOrg" element={<ReestrNecomOrg/>}/>
                            <Route path="/mediacia/vcdorlenok" element={<VDCOrlenok/>}/>
                            <Route
                                path="/spec-materials-category/materialy-antinarkoticheskogo-soderzhaniya-razrabotannye-v-subektah-rossijskoj-federatsii-informatsionnye-materialy-dlya-roditelej"
                                element={<FilesAntiNarcoticParents/>}/>
                            <Route path="/spec-value-of-life/poleznye-ssylki" element={<ValueRef/>}/>
                            <Route path="/concurs"
                                   element={<Concurs setPageConcurs={setPageConcurs} isConcurs={isConcurs}/>}/>
                            <Route path="/news/den-bez-tabaka-2023" element={<News29052023/>}/>
                            <Route path="/news/3105200222" element={<News310520222/>}/>

                            <Route
                                path="/news/pozdravlenie-ministra-prosveshheniya-rossijskoj-federatsii-sergeya-kravtsova-s-dnyom-rossijskoj-nauki"
                                element={<News08022022/>}/>
                            <Route
                                path="/news/vyborprofi-proekt-po-professionalnomu-samoopredeleniyu-detej-vospityvayushhihsya-v-zameshhayushhih-semyah"
                                element={<News2102022/>}/>
                            <Route
                                path="/news/kursy-povysheniya-kvalifikatsii-dlya-rukovoditelej-i-spetsialistov-organov-opeki-i-popechitelstva"
                                element={<News02032022/>}/>
                            <Route
                                path="/news/tematicheskie-vebinary-po-voprosam-svyazannym-s-sovmestnym-nahozhdeniem-s-rebenkom-iz-organizatsii-dlya-detej-sirot-i-detej-ostavshihsya-bez-popecheniya-roditelej-pri-okazanii-rebenku-meditsinskoj-pom"
                                element={<News10032022/>}/>
                            <Route path="/news/obyasnyaem-rf" element={<News01042022/>}/>
                            <Route
                                path="/news/ob-itogah-deyatelnosti-organov-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih-grazhdan-za-2021-god"
                                element={<News11042022/>}/>
                            <Route
                                path="/news/press-reliz-vebinara-populyarizatsiya-meropriyatij-desyatiletiya-detstva-problemy-mehanizmy-praktiki"
                                element={<News15042022/>}/>
                            <Route path="/news/subsidii-rabotodatelyam-za-trudoustrojstvo-molodezhi"
                                   element={<News23032022/>}/>
                            <Route path="/news/videokonferentsiya-mediatsiya-kak-sposob-uregulirovaniya-sporov"
                                   element={<News120422/>}/>
                            <Route
                                path="/news/v-gosudarstvennoj-dume-proshli-parlamentskie-slushaniya-po-voprosu-o-deyatelnosti-organov-opeki-i-popechitelstva"
                                element={<News180422/>}/>
                            <Route path="/news/32040" element={<News270422/>}/>
                            <Route
                                path="/news/otvetstvennye-za-desyatiletie-detstva-v-subektah-rossijskoj-federatsii-poluchili-prakticheskie-navyki-informatsionnoj-raboty"
                                element={<News280422/>}/>
                            <Route
                                path="/news/pozdravlenie-ministra-prosveshheniya-rossijskoj-federatsii-sergeya-kravtsova-s-1-maya-prazdnikom-vesny-i-truda"
                                element={<News010522/>}/>
                            <Route path="/news/s-1-iyunya-v-rossii-nachnut-rabotu-letnie-proforientatsionnye-shkoly"
                                   element={<News170522/>}/>
                            <Route
                                path="/news/pozdravlenie-ministra-prosveshheniya-rossijskoj-federatsii-sergeya-kravtsova-s-dnem-pobedy"
                                element={<News08052022/>}/>
                            <Route
                                path="/news/itogi-vserossijskoj-nauchno-prakticheskoj-konferentsii-na-zashhite-detstva-strategii-praktiki-resursy"
                                element={<News010622/>}/>
                            <Route path="/bez-rubriki/32302" element={<News10622/>}/>
                            <Route
                                path="/news/startoval-vserossijskij-konkurs-sotsialnoj-reklamy-stil-zhizni-zdorove-2022"
                                element={<News16062022/>}/>
                            <Route
                                path="/news/otkryt-dostup-dlya-zaochnogo-uchastiya-vo-vserossijskoj-nauchno-prakticheskoj-konferentsii-na-zashhite-detstva-strategii-praktiki-resursy"
                                element={<News20062022/>}/>
                            <Route
                                path="/news/v-pskove-s-22-po-25-iyunya-projdet-mezhdunarodnyj-festival-osobennyh-lyudej-drugoe-iskusstvo"
                                element={<News20062022Pskov/>}/>
                            <Route
                                path="/news/28-iyunya-2022-g-v-9-30-po-moskovskomu-vremeni-sostoitsya-vebinar-dlya-rukovoditelej-i-spetsialistov-tsentrov-psihologo-pedagogicheskoj-meditsinskoj-i-sotsialnoj-pomoshhi-ppms-tsentr-deyatelnost-ppms"
                                element={<News24062022/>}/>
                            <Route
                                path="/news/sostoitsya-x-vserossijskij-sezd-rukovoditelej-organizatsij-dlya-detej-sirot-i-detej-ostavshihsya-bez-popecheniya-roditelej"
                                element={<News240622/>}/>
                            <Route path="/news/v-pskove-zavershilsya-mezhdunarodnyj-festival-drugoe-iskusstvo"
                                   element={<News040722/>}/>
                            <Route
                                path="/news/vnimaniyu-spetsialistov-razrabotany-rekomendatsii-dlya-roditelej-i-pamyatki-dlya-podrostkov-napravlennye-na-obespechenie-bezopasnosti-v-detsko-podrostkovoj-srede"
                                element={<News08072022/>}/>
                            <Route
                                path="/news/x-sezd-rukovoditelej-organizatsij-dlya-detej-sirot-i-detej-ostavshihsya-bez-popecheniya-roditelej"
                                element={<News120722/>}/>
                            <Route path="/news/sostoyalsya-i-mezhregionalnyj-forum-podrostki-360"
                                   element={<FirstTeenagers360/>}/>
                            <Route
                                path="/news/vnimaniyu-spetsialistov-analiticheskij-doklad-izuchenie-motivov-proyavleniya-deviantnogo-povedeniya-obuchayushhimisya-prichin-uslovij-sposobstvuyushhih-formirovaniyu-takogo-povedeniya-a-takzhe-mer-pri"
                                element={<News01082022/>}/>
                            <Route
                                path="/news/nachinaet-rabotu-federalnyj-tsentr-razvitiya-programm-sotsializatsii-podrostkov"
                                element={<News010822/>}/>
                            <Route path="/news/vserossijskij-molodyozhnyj-forum-territoriya-smyslov-2022"
                                   element={<TerritoryOfMeanings2022/>}/>
                            <Route
                                path="/news/razmeshhen-sbornik-materialov-vserossijskoj-nauchno-prakticheskoj-konferentsii-po-aktualnym-problemam-profilaktiki-deviantnogo-povedeniya-nesovershennoletnih-na-zashhite-detstva-strategii-praktiki-res"
                                element={<News110822/>}/>
                            <Route
                                path="/news/obsuzhdenie-izmenenij-v-professionalnyj-standart-spetsialist-organa-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih"
                                element={<News17082022/>}/>
                            <Route
                                path="/news/na-storone-rebenka-pervyj-vserossijskij-konkurs-spetsialistov-organov-opeki-i-popechitelstva"
                                element={<News08092022/>}/>
                            <Route
                                path="/news/nauchno-metodicheskoe-obespechenie-edinogo-podhoda-vnedreniya-sotsialnyh-v-tom-chisle-obrazovatelnyh-praktik-s-dokazannoj-effektivnostyu-v-chasti-realizatsii-meropriyatij-desyatiletiya-detstva"
                                element={<News140922/>}/>
                            <Route path="/news/33345" element={<News14092022/>}/>
                            <Route
                                path="/news/goryachaya-liniya-po-voprosam-mediatsii-i-primireniya-v-otnoshenii-nesovershennoletnih-obuchayushhihsya-obrazovatelnyh-organizatsij1"
                                element={<News22092022/>}/>
                            <Route path="/news/den-bez-tabaka-2023" element={<News29052023/>}/>
                            <Route
                                path="/news/soporovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-iyune-2022-goda"
                                element={<SupportOfMediationServicesJune2022/>}/>
                            <Route
                                path="/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-mae-2022-goda"
                                element={<SupportOfMediationServicesMay2022/>}/>
                            <Route
                                path="/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-aprele-2022-goda"
                                element={<SupportOfMediationServicesApril2022/>}/>
                            <Route path="/news/24062023" element={<Putin2406/>}/>
                            <Route path="/news/240612023" element={<Putin24061/>}/>
                            <Route path="/news/05102023" element={<News051023/>}/>
                            <Route path="/news/16122024" element={<News161224/>}/>
                            <Route
                                path="/webinars/organizatsiya-sistemnoj-raboty-po-profilaktike-rannego-vovlecheniya-v-nezakonnoe-potreblenie-narkoticheskih-sredstv-i-psihotropnyh-veshhestv-obuchayushhihsya-obrazovatelnyh-organizatsij-v-subektah-ros/"
                                element={<OSRPRV/>}/>
                            <Route
                                path="/webinars/vserossijskie-meropriyatiya-tsentra-po-formirovaniyu-tsennostej-bezopasnogo-i-zdorovogo-obraza-zhizni/"
                                element={<VMT/>}/>
                            <Route path="/webinars/resursy-roditelya" element={<ParentResources/>}/>
                            <Route path="/news/110723" element={<News110723/>}/>
                            <Route
                                path="/webinars/organizatsiya-raboty-po-predotvrashheniyu-detskoj-smertnosti-dlya-pedagogov-psihologov/"
                                element={<PreventionOfChildMortality/>}/>
                            <Route path="/news/130723" element={<News13072023/>}/>
                            <Route path="/news/170723" element={<News170723/>}/>
                            <Route path="/news/170723-2" element={<News1707232/>}/>
                            <Route path="/news/190723" element={<News190723/>}/>
                            <Route path="/news/200723" element={<News200723/>}/>
                            <Route path="/news/210723" element={<News210723/>}/>
                            <Route path="/news/030823" element={<News030823/>}/>
                            <Route path="/news/210823" element={<News210823/>}/>
                            <Route path="/webinars/ogranichenie-i-nakazanie-effektivnost-v-vospitanii"
                                   element={<OgranichenieNakazanie/>}/>
                            <Route path="/webinars/psihologiya-zavisimogo-povedeniya" element={<ConsDANesov/>}/>
                            <Route
                                path="/webinars/osobennosti-podrostkovogo-vozrasta-s-tochki-zreniya-psihologii-sotsiologii-i-etologii-podrostkovaya-deviantnost"
                                element={<Osobennosti/>}/>
                            <Route
                                path="/webinars/exameni-bez-stressa"
                                element={<ExamsWiStress/>}/>
                            <Route
                                path="/webinars/tws"
                                element={<TerritoryWithout/>}/>
                            <Route
                                path="/webinars/o-provedenii-vserossijskogo-konkursa-sotsialnoj-reklamy-stil-zhizni-zdorove-2020"
                                element={<LifeStyle2020/>}/>

                            <Route
                                path="/news/091023"
                                element={<News091023/>}/>
                            <Route
                                path="/news/131023"
                                element={<News131023/>}/>
                            <Route
                                path="/news/191023"
                                element={<News191023/>}/>
                            <Route
                                path="/news/191023-2"
                                element={<News1910232/>}/>
                            <Route
                                path="/news/201023"
                                element={<News201023/>}/>
                            <Route
                                path="/news/231023"
                                element={<News231023/>}/>
                            <Route
                                path="/news/051023"
                                element={<News051023/>}/>
                            <Route
                                path="/news/311023"
                                element={<News311023/>}/>
                            <Route
                                path="/news/031123"
                                element={<News031123/>}/>
                            <Route
                                path="/news/101123"
                                element={<News101123/>}/>
                            <Route
                                path="/news/160824"
                                element={<News160824/>}/>
                            <Route
                                path="/news/201123"
                                element={<News201123/>}/>
                            <Route
                                path="/news/231123"
                                element={<News231123/>}/>
                            <Route
                                path="/news/241123"
                                element={<News241123/>}/>
                            <Route
                                path="/news/271123"
                                element={<News271123/>}/>
                            <Route
                                path="/news/281123"
                                element={<News281123/>}/>
                            <Route
                                path="/news/2811232"
                                element={<News2811232/>}/>
                            <Route
                                path="/news/2811233"
                                element={<News2811233/>}/>
                            <Route
                                path="/news/291123"
                                element={<News291123/>}/>
                            <Route
                                path="/news/041223"
                                element={<News041223/>}/>
                            <Route
                                path="/news/111223"
                                element={<News111223/>}/>
                            <Route path="/edu/test" element={<EduTest/>}/>
                            <Route
                                path="/news/131223"
                                element={<News131223/>}/>
                            <Route
                                path="/news/141223"
                                element={<News141223/>}/>
                            <Route
                                path="/news/1412232"
                                element={<News1412232/>}/>
                            <Route
                                path="/news/151223"
                                element={<News151223/>}/>
                            <Route
                                path="/news/191223"
                                element={<News191223/>}/>
                            <Route
                                path="/news/211223"
                                element={<News211223/>}/>
                            <Route
                                path="/news/2112232"
                                element={<News2112232/>}/>
                            <Route
                                path="/news/271223"
                                element={<News271223/>}/>
                            <Route
                                path="/news/281223"
                                element={<News281223/>}/>

                            <Route
                                path="/news/090124"
                                element={<News090124/>}/>
                            <Route
                                path="/news/290124"
                                element={<News290124/>}/>
                            <Route path="/news/010224" element={<News010224/>}/>
                            <Route
                                path="/news/060224"
                                element={<News060224/>}/>
                            <Route
                                path="/news/190224"
                                element={<News190224/>}/>
                            <Route
                                path="/news/220224"
                                element={<News220224/>}/>
                            <Route
                                path="/news/280224"
                                element={<News280224/>}/>
                            <Route
                                path="/news/010324"
                                element={<News010324/>}/>
                            <Route
                                path="/news/040324"
                                element={<News040324/>}/>
                            <Route
                                path="/news/060324"
                                element={<News060324/>}/>
                            <Route
                                path="/news/290524"
                                element={<News290524/>}/>
                            <Route
                                path="/news/120324"
                                element={<News120324/>}/>
                            <Route
                                path="/news/180324"
                                element={<News180324/>}/>
                            <Route
                                path="/news/280324"
                                element={<News280324/>}/>
                            <Route
                                path="/news/2803242"
                                element={<News2803242/>}/>
                            <Route
                                path="/news/020424"
                                element={<News020424/>}/>
                            <Route
                                path="/news/030424"
                                element={<News030424/>}/>
                            <Route
                                path="/news/050424"
                                element={<News050424/>}/>
                            <Route
                                path="/news/150424"
                                element={<News150424/>}/>
                            <Route
                                path="/news/170424"
                                element={<News170424/>}/>
                            <Route
                                path="/news/220424"
                                element={<News220424/>}/>
                            <Route
                                path="/news/2204242"
                                element={<News2204242/>}/>
                            <Route
                                path="/video_mediacia"
                                element={<VideoMediacia/>}/>
                            <Route
                                path="/video_mediacia/parents"
                                element={<VideoMediaciaParents/>}/>
                            <Route
                                path="/video_mediacia/children"
                                element={<VideoMediaciaChildren/>}/>
                            <Route
                                path="/video_mediacia/piece"
                                element={<VideoMediaciaPiece/>}/>
                            <Route element={<SUVUNavigator/>} path="/navigator"/>
                            <Route element={<NewSUVU/>} path="/new_suvu"/>
                            <Route element={<News170221/>} path="/news/170221"/>
                            <Route element={<News100321/>} path="/news/100321"/>
                            <Route element={<News020421/>} path="/news/020421"/>
                            <Route element={<News270421/>} path="/news/270421"/>
                            <Route element={<News250521/>} path="/news/250521"/>
                            <Route element={<News011121/>} path="/news/011121"/>
                            <Route element={<News171221/>} path="/news/171221"/>
                            <Route element={<News230424/>} path="/news/230424"/>
                            <Route element={<News080524/>} path="/news/080524"/>
                            <Route element={<News140523/>} path="/news/140524"/>
                            <Route element={<News210524/>} path="/news/210524"/>
                            <Route element={<News240524/>} path="/news/240524"/>
                            <Route element={<News250524/>} path="/news/270524"/>
                            <Route element={<News2705242/>} path="/news/2705242"/>
                            <Route element={<News280524/>} path="/news/280524"/>
                            <Route element={<News2805242/>} path="/news/2805242"/>
                            <Route element={<News2905242/>} path="/news/2905242"/>
                            <Route element={<News300524/>} path="/news/300524"/>
                            <Route element={<News3005242/>} path="/news/3005242"/>
                            <Route element={<News0106242/>} path="/news/0106242"/>
                            <Route element={<News030624/>} path="/news/030624"/>
                            <Route element={<News040624/>} path="/news/040624"/>
                            <Route element={<News0406242/>} path="/news/0406242"/>
                            <Route element={<News0406243/>} path="/news/0406243"/>
                            <Route element={<News110624/>} path="/news/110624"/>
                            <Route element={<News120624/>} path="/news/120624"/>
                            <Route element={<News140624/>} path="/news/140624"/>
                            <Route element={<News170624/>} path="/news/170624"/>
                            <Route element={<News200624/>} path="/news/200624"/>
                            <Route element={<News220624/>} path="/news/220624"/>
                            <Route element={<News260624/>} path="/news/260624"/>
                            <Route element={<News270624/>} path="/news/270624"/>
                            <Route element={<News2706242/>} path="/news/2706242"/>
                            <Route element={<News2706243/>} path="/news/2706243"/>
                            <Route element={<News280624/>} path="/news/280624"/>
                            <Route element={<News030724/>} path="/news/030724"/>
                            <Route element={<News160724/>} path="/news/160724"/>
                            <Route element={<News260724/>} path="/news/260724"/>
                            <Route element={<News260724Reg/>} path="/news/260724/reg"/>
                            <Route element={<News160724/>} path="/news/160724"/>
                            <Route element={<News150824/>} path="/news/150824"/>
                            <Route element={<News210824/>} path="/news/210824"/>
                            <Route element={<News260824/>} path="/news/260824"/>
                            <Route element={<News280824/>} path="/news/280824"/>
                            <Route element={<News090924/>} path="/news/090924"/>
                            <Route element={<News260924/>} path="/news/260924"/>
                            <Route element={<News011024/>} path="/news/011024"/>
                            <Route element={<News091024/>} path="/news/091024"/>
                            <Route element={<News101024/>} path="/news/101024"/>
                            <Route element={<News111024/>} path="/news/111024"/>
                            <Route element={<News181024/>} path="/news/181024"/>
                            <Route element={<News241024/>} path="/news/241024"/>
                            <Route element={<News251024/>} path="/news/251024"/>
                            <Route element={<News291024/>} path="/news/291024"/>
                            <Route element={<News061124/>} path="/news/061124"/>
                            <Route element={<News121124/>} path="/news/121124"/>
                            <Route element={<News151124/>} path="/news/151124"/>
                            <Route element={<New201124/>} path="/news/201124"/>
                            <Route element={<News211124/>} path="/news/211124"/>
                            <Route element={<News221124/>} path="/news/221124"/>
                            <Route element={<News291124/>} path="/news/291124"/>
                            <Route element={<News2911242/>} path="/news/2911242"/>
                            <Route element={<News021224/>} path="/news/021224"/>
                            <Route element={<News121224/>} path="/news/121224"/>
                            <Route element={<News1212242/>} path="/news/1212242"/>
                            <Route element={<News281224/>} path="/news/281224"/>
                            <Route element={<News2812242/>} path="/news/2812242"/>
                            <Route element={<Rules/>} path="/rec_rules"/>
                        </Routes>
                    </div>
                    {/*<Warning/>*/}
                    <Footer/>

                </div>

            </BrowserRouter>
        );

    } else {
        return <BrowserRouter>
            <div className={styl}>
                <Routes>
                    <Route path="/concurs" element={<Concurs setPageConcurs={setPageConcurs} isConcurs={isConcurs}/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    }
}

const Warning = () => {
    return (
        <div className="warning">
            Внимание! На сайте ведутся тех. работы,<br/>отправка заявок может быть недоступна!
        </div>
    )
}

const mapStateToProps = (state) => (
    {initialized: state.app.initialized})

let AppContainer = compose(
    connect(mapStateToProps, {}))(App)

const FCPRCFrontApp = () => {
    return <>
        <Provider store={store}>
            <App/>
        </Provider>
    </>

}
export default FCPRCFrontApp;
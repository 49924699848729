import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"


const FilesUstav = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/aboutus").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>

        <div className={s.filesLeftPage}>
            <div>
                <div className={s.newHeader}>
                    <h2>
                        Учредительные документы
                    </h2>
                </div>

                <FileLeftLine text={respData[24]?.description} refers={respData[24]?.file} ico={ico}/>
                <FileLeftLine text={respData[25]?.description} refers={respData[25]?.file} ico={ico}/>
                <FileLeftLine text={respData[26]?.description} refers={respData[26]?.file} ico={ico}/>
                <FileLeftLine text={respData[27]?.description} refers={respData[27]?.file} ico={ico}/>
                <FileLeftLine text={respData[28]?.description} refers={respData[28]?.file} ico={ico}/>
                <div className={s.newHeader}>
                    <h2>
                        Учетная политика
                    </h2>
                </div>
                <FileLeftLine text={respData[153]?.description} refers={respData[153]?.file} ico={ico}/>
                <FileLeftLine text={respData[154]?.description} refers={respData[154]?.file} ico={ico}/>
                <FileLeftLine text={respData[155]?.description} refers={respData[155]?.file} ico={ico}/>
                <FileLeftLine text={respData[156]?.description} refers={respData[156]?.file} ico={ico}/>
                <div className={s.newHeader}>
                    <h2>
                        Локально-нормативные акты
                    </h2>
                </div>
                <FileLeftLine text={respData[171]?.description} refers={respData[171]?.file} ico={ico}/>
                <FileLeftLine text="Политика ФГБУ «Центр защиты прав и интересов детей» в отношении обработки персональных данных" refers="/media/media/behavior/Политика_ФГБУ_Центр_защиты_прав_и_интересов_детей_в_отношении_обработки_п_DLl5cF6.pdf" ico={ico}/>
                <FileLeftLine text="Положение о конфликте интересов в ФГБУ «Центр защиты прав и интересов детей»" refers="/media/media/behavior/Положение_о_конфликте_интересов_в_ФГБУ_Центр_защиты_прав_и_интересов_детей.pdf" ico={ico}/>
                <FileLeftLine text="Кодекс этики и служебного поведения работников ФГБУ «Центр защиты прав и интересов детей»" refers="/media/media/behavior/Кодекс_этики_и_служебного_поведения_работников_ФГБУ_Центр_защиты_прав_и_и_EiPgsBk.pdf" ico={ico}/>
                <FileLeftLine text="Положение об обработке и защите персональных данных работников (иных лиц) в ФГБУ «Центр защиты прав и интересов детей»" refers="/media/media/behavior/Положение_об_обработке_и_защите_персональных_данных_работников_иных_лиц.pdf" ico={ico}/>
                <FileLeftLine text="Правила внутреннего трудового распорядка работников ФГБУ «Центр защиты прав и интересов детей»" refers="/media/media/behavior/Правила_внутреннего_трудового_распорядка_работников_ФГБУ_Центр_защиты_пра_qkrPXDS.pdf" ico={ico}/>
            </div>

        </div>
    </>
}
export default FilesUstav
import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News211124_1.png"
import img2 from "../../assets/News/News211124_2.png"
import img3 from "../../assets/News/News211124_3.png"

const News211124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Стартовала регистрация на II Всероссийский правовой диктант для школьников и студентов колледжей #ВПРАВЕ
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Побьем рекорд вместе! Год назад во Всероссийском правовом диктанте для школьников и студентов колледжей
            #ВПРАВЕ приняли участие более 500 тысяч человек со всей России.
            В этом году мы ставим перед собой новую цель — собрать 1 000 000 участников. Приглашаем вас присоединиться к
            нам и стать частью нашего масштабного проекта.
            <p></p>
            Это отличная возможность:
            <ul>
                <li>Проверить свои знания в сфере законов России</li>
                <li>Узнать, как максимально эффективно использовать свои права</li>
                <li>Научиться защищать свои правовые интересы и способствовать формированию правовой грамотности в
                    стране
                </li>
            </ul>
            Диктант пройдет с 25 ноября по 1 декабря.
            <p></p>
            Как принять участие?<br/>
            Зарегистрироваться на платформе <a href="https://будьвправе.рф/"
                                               style={{fontWeight: "bold"}}>будьвправе.рф</a><br/>
            Приглашайте друзей, одноклассников, однокурсников! Давайте вместе повысим уровень правовой грамотности в
            нашей стране #мывправе<br/>
            Проект реализуется при поддержке Фонда президентских грантов
            <p></p>
            <a href="https://будьвправе.рф/" style={{fontWeight: "bold"}}>Ссылка на сайт: https://будьвправе.рф/</a>
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News211124;
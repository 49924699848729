import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2911242.jpg"
import img2 from "../../assets/News/News2911242_2.jpg"
import img3 from "../../assets/News/News2911242_3.png"
import img4 from "../../assets/News/News2911242_4.png"
import img5 from "../../assets/News/News2911242_5.png"

const News2911242 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных
            учреждениях в ноябре 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Сотрудниками отдела медиации ФГБУ «Центр защиты прав и интересов
            детей» были проведены заключительные в 2024 году мероприятия по
            сопровождению служб медиации в специальных учебно-воспитательных
            учреждениях, подведомственных Минпросвещения России (СУВУ).
            <p></p>
            Мероприятия прошли в дистанционном формате 7-8 ноября с Абаканским
            СУВУ, Астраханским СУВУ, Щекинским СУВУ, Санкт-Петербургским
            СУВУ, Раифским СУВУ и Себежским СУВУ и 28-29 ноября с Калтанским
            СУВУ, Ишимбайским СУВУ, Омским СУВУ, Рефтинским СУВУ и
            Майкопским СУВУ.
            <p></p>
            В рамках групповых консультаций для специалистов служб медиации
            специальных учебно-воспитательных учреждений 7 и 28 ноября была
            разобрана конфликтная ситуации из опыта работы, а также обсуждалось
            актуальное состояние служб и планы на 2025 год.
            <p></p>
            8 и 29 ноября состоялись интерактивные занятия с обучающимися СУВУ, на
            которых мальчики и девочки стали участниками деловой игры, направленной
            на формирование и совершенствование навыков ведения переговоров.
            Встречи прошли в дружеской и уважительной атмосфере.
            <p></p>
            Мероприятия проводили сотрудники отдела медиации Центра защиты прав и
            интересов детей: аналитик Быкова Марина Викторовна, аналитик Тюльканов Сергей Леонидович, аналитик II
            категории Жодзишская Лидия
            Николаевна.
            <p></p>
            Работа со службами медиации СУВУ будет продолжена в 2025 году на
            основании Межведомственного плана комплексных мероприятий по
            реализации Концепции развития сети служб медиации, в целях реализации
            восстановительного правосудия в отношении детей, в том числе
            совершивших общественно опасные деяния, но не достигших возраста, с
            которого наступает уголовная ответственность в Российской Федерации, до
            2025 года.
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <ToNews/>
    </div>
}


export default News2911242;
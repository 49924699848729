import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2812242.jpg"
import img2 from "../../assets/News/News2812242_2.jpg"

const News2812242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Мэр города Москвы С.С. Собянин выразил благодарность ФГБУ "Центру защиты прав и интересов детей" за большой
            вклад в организацию и проведение выборов Президента Российской Федерации.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{textAlign: "center"}}>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News2812242;